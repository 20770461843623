import React, { useEffect, useRef } from 'react';

const CoverGenerator = ({
                            preset = '',
                            text = 'Course Title',
                            fontSize = '32px',
                            fontColor = '#000000',
                            bgColor = '#ffffff',
                            borderColor = '#000000',
                            shadow = 'off',
                            fontFamily = 'Roboto',
                            borderWidth = '2px',
                            backgroundGradientTemplate = '',
                            width = 520,
                        }) => {
    const coverRef = useRef(null); // Ref for the cover element
    const coverTextRef = useRef(null); // Ref for the text element

    // Function to dynamically load fonts
    const loadFonts = () => {
        const fonts = [
            'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=Lobster&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap&subset=cyrillic',
            'https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap&subset=cyrillic',
        ];

        fonts.forEach((font) => {
            const link = document.createElement('link');
            link.href = font;
            link.rel = 'stylesheet';
            document.head.appendChild(link);
        });
    };

    // Function to get preset values
    const getPreset = (preset) => {
        const presets = {
            modern: {
                fontSize: '2.5vw',
                fontColor: '#ffffff',
                fontFamily: 'Montserrat',
                backgroundGradientTemplate: 't1',
                borderColor: '#0093E9',
                borderWidth: '0px',
                shadow: 'on',
            },
            classic: {
                fontSize: '2.5vw',
                fontColor: '#fff',
                fontFamily: 'Playfair Display',
                backgroundGradientTemplate: 't10',
                borderColor: '#2b5876',
                borderWidth: '0px',
                shadow: 'off',
            },
            minimal: {
                fontSize: '28px',
                fontColor: '#000000',
                fontFamily: 'PT Sans',
                bgColor: '#ffffff',
                borderColor: '#000000',
                borderWidth: '0px',
                shadow: 'off',
            },
            vibrant: {
                fontSize: '34px',
                fontColor: '#ffe1c7',
                fontFamily: 'Raleway',
                backgroundGradientTemplate: 't14',
                borderColor: '#FF512F',
                borderWidth: '0px',
                shadow: 'on',
            },
            elegant: {
                fontSize: '38px',
                fontColor: '#ffffff',
                fontFamily: 'Montserrat',
                backgroundGradientTemplate: 't2',
                borderColor: '#C850C0',
                borderWidth: '0px',
                shadow: 'off',
            },
            desc: {
                fontSize: '34px',
                fontColor: '#ffffff',
                fontFamily: 'Caveat',
                backgroundGradientTemplate: 't17',
                borderColor: '#282828',
                borderWidth: '0px',
                shadow: 'off'
            },
            blueDino: {
                fontSize: '34px',
                fontColor: '#ffffff',
                fontFamily: 'Fira Sans',
                backgroundGradientTemplate: 'blueDino',
                borderColor: '#0078b9',
                borderWidth: '0px',
                shadow: 'off'
            },
            yellowCat: {
                fontSize: '34px',
                fontColor: '#000000',
                fontFamily: 'Fira Sans',
                backgroundGradientTemplate: 't16',
                borderColor: '#FEF346',
                borderWidth: '0px',
                shadow: 'off'
            },
            bold: {
                fontSize: '40px',
                fontColor: '#ffffff',
                fontFamily: 'Montserrat',
                backgroundGradientTemplate: 't3',
                borderColor: '#8EC5FC',
                borderWidth: '0px',
                shadow: 'off',
            },
            fresh: {
                fontSize: '38px',
                fontColor: '#2b5876',
                fontFamily: 'Montserrat',
                backgroundGradientTemplate: 't9',
                borderColor: '#ff758c',
                borderWidth: '0px',
                shadow: 'off',
            },
            retro: {
                fontSize: '35px',
                fontColor: '#ffffff',
                fontFamily: 'Exo 2',
                backgroundGradientTemplate: 't8',
                borderColor: '#FC466B',
                borderWidth: '0px',
                shadow: 'on',
            },
            calm: {
                fontSize: '37px',
                fontColor: '#ffffff',
                fontFamily: 'Amatic SC',
                backgroundGradientTemplate: 't10',
                borderColor: '#F3904F',
                borderWidth: '0px',
                shadow: 'off',
            },
            futuristic: {
                fontSize: '38px',
                fontColor: '#ffffff',
                fontFamily: 'Roboto',
                backgroundGradientTemplate: 't7',
                borderColor: '#00C9FF',
                borderWidth: '0px',
                shadow: 'on',
            },
            darkMode: {
                fontSize: '34px',
                fontColor: '#ffffff',
                fontFamily: 'Fira Sans',
                backgroundGradientTemplate: 't12',
                borderColor: '#42275a',
                borderWidth: '0px',
                shadow: 'on',
            },
            nature: {
                fontSize: '37px',
                fontColor: '#ffffff',
                fontFamily: 'PT Sans',
                backgroundGradientTemplate: 't13',
                borderColor: '#00b09b',
                borderWidth: '0px',
                shadow: 'off',
            },
            sunny: {
                fontSize: '37px',
                fontColor: '#ffffff',
                fontFamily: 'Montserrat',
                backgroundGradientTemplate: 't5',
                borderColor: '#FFFB7D',
                borderWidth: '0px',
                shadow: 'on',
            },
            pastel: {
                fontSize: '30px',
                fontColor: '#ffffff',
                fontFamily: 'Raleway',
                backgroundGradientTemplate: 't11',
                borderColor: '#ffdde1',
                borderWidth: '0px',
                shadow: 'off',
            },
            artistic: {
                fontSize: '39px',
                fontColor: '#ffffff',
                fontFamily: 'Lobster',
                backgroundGradientTemplate: 't6',
                borderColor: '#FF5F6D',
                borderWidth: '0px',
                shadow: 'on',
            },
        };

        return presets[preset] || null;
    };

    // Function to get gradient background
    const getGradient = (template) => {
        const backgrounds = {
            t1: {
                backgroundColor: '#0093E9',
                backgroundImage: 'linear-gradient(160deg, #0093E9 0%, #80D0C7 100%)',
            },
            t2: {
                backgroundColor: '#4158D0',
                backgroundImage: 'linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)',
            },
            t3: {
                backgroundColor: '#8EC5FC',
                backgroundImage: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)',
            },
            t4: {
                backgroundColor: '#F3904F',
                backgroundImage: 'linear-gradient(135deg, #F3904F 0%, #3B4371 100%)',
            },
            t5: {
                backgroundColor: '#85FFBD',
                backgroundImage: 'linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%)',
            },
            t6: {
                backgroundColor: '#FF5F6D',
                backgroundImage: 'linear-gradient(to right, #FF5F6D, #FFC371)',
            },
            t7: {
                backgroundColor: '#00C9FF',
                backgroundImage: 'linear-gradient(to right, #00C9FF, #92FE9D)',
            },
            t8: {
                backgroundColor: '#FC466B',
                backgroundImage: 'linear-gradient(to right, #FC466B, #3F5EFB)',
            },
            t9: {
                backgroundColor: '#ff758c',
                backgroundImage: 'linear-gradient(to right, #ff758c, #ff7eb3)',
            },
            t10: {
                backgroundColor: '#2b5876',
                backgroundImage: 'linear-gradient(to right, #2b5876, #4e4376)',
            },
            t11: {
                backgroundColor: '#ee9ca7',
                backgroundImage: 'linear-gradient(to right, #ee9ca7, #ffdde1)',
            },
            t12: {
                backgroundColor: '#42275a',
                backgroundImage: 'linear-gradient(to right, #42275a, #734b6d)',
            },
            t13: {
                backgroundColor: '#00b09b',
                backgroundImage: 'linear-gradient(to right, #00b09b, #96c93d)',
            },
            t14: {
                backgroundColor: '#FF512F',
                backgroundImage: 'linear-gradient(to right, #FF512F, #F09819)',
            },
            t15: {
                backgroundColor: '#56CCF2',
                backgroundImage: 'linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%)',
            },
            t16: {
                backgroundColor: '#FEF346',
                backgroundImage: 'url(https://coob.app/assets/images/ycat.jpg)',
            },
            t17: {
                backgroundColor: '#222222',
                backgroundImage: 'url(https://coob.app/assets/images/desc.jpg)',
            },
            blueDino: {
                backgroundColor: '#0078b9',
                backgroundImage: 'url(https://coob.app/assets/images/blueDino.jpg)',
            },
        };

        return backgrounds[template] || null;
    };

    // Function to apply styles
    const applyStyles = () => {
        const presetParams = preset ? getPreset(preset) : null;
        const finalParams = presetParams
            ? { preset, ...presetParams }
            : {
                preset,
                text,
                fontSize, // Keep the original fontSize from props
                fontColor,
                bgColor,
                borderColor,
                shadow,
                fontFamily,
                borderWidth,
                backgroundGradientTemplate,
            };

        if (coverRef.current && coverTextRef.current) {
            const cover = coverRef.current;
            const coverText = coverTextRef.current;

            // Apply styles to text element
            coverText.style.color = finalParams.fontColor;
            coverText.style.fontFamily = finalParams.fontFamily;
            if (width < 520) {
                coverText.style.fontSize = `calc(${parseFloat(finalParams.fontSize)}px * (100% / ${width}px))`; // Calculate dynamic font size based on container width
            } else {
                coverText.style.fontSize = finalParams.fontSize;
            }

            // Apply background styles to cover element
            if (finalParams.backgroundGradientTemplate) {
                const gradient = getGradient(finalParams.backgroundGradientTemplate);
                if (gradient) {
                    cover.style.backgroundColor = gradient.backgroundColor;
                    cover.style.backgroundImage = gradient.backgroundImage;
                } else {
                    cover.style.backgroundColor = finalParams.bgColor;
                }
            } else {
                cover.style.backgroundColor = finalParams.bgColor;
            }

            cover.style.borderColor = finalParams.borderColor;
            cover.style.borderWidth = finalParams.borderWidth;

            if (finalParams.shadow === 'on') {
                coverText.style.textShadow = '2px 2px 5px rgba(0, 0, 0, 0.5)';
            } else {
                coverText.style.textShadow = 'none';
            }
        }
    };

    // Applying styles when the component mounts
    useEffect(() => {
        loadFonts();
        applyStyles();
    }, [preset, text, fontSize, fontColor, bgColor, borderColor, shadow, fontFamily, borderWidth, backgroundGradientTemplate]);

    return (
        <div
            id="cover"
            ref={coverRef}
            style={{
                width: '100%',
                maxWidth: '520px',
                height: 'auto',
                aspectRatio: '520 / 315', // Maintain the aspect ratio
                border: '2px solid #000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontFamily: 'Roboto, sans-serif',
                position: 'relative',
            }}
        >
            <h1
                ref={coverTextRef}
                id="cover-text"
                style={{
                    margin: 0,
                    padding: 0,
                }}
            >
                {text}
            </h1>
        </div>
    );
};

export default CoverGenerator;