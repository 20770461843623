import EXIF from 'exif-js';

/**
 * Обрабатывает изображение, поворачивая его на 90 градусов влево, если это требуется.
 * @param {File} file - Изображение, загруженное пользователем.
 * @returns {Promise<Blob>} - Возвращает исправленное изображение в формате Blob.
 */
export const processImageWithExif = async (file) => {
    return new Promise((resolve, reject) => {
        if (!file || !file.type.startsWith('image/')) {
            return reject(new Error('Файл не является изображением.'));
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = async () => {
                try {
                    // Получаем EXIF-данные
                    await EXIF.getData(img);
                    const orientation = EXIF.getTag(img, 'Orientation');

                    // Создаем canvas для отрисовки изображения
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    if (orientation === 8) { // Поворот на 90 градусов влево
                        canvas.width = img.height;
                        canvas.height = img.width;

                        // Поворачиваем canvas
                        ctx.translate(0, canvas.height);
                        ctx.rotate(-Math.PI / 2);
                    } else {
                        // Если не требуется поворот, устанавливаем исходные размеры
                        canvas.width = img.width;
                        canvas.height = img.height;
                    }

                    // Отрисовываем изображение на canvas
                    ctx.drawImage(img, 0, 0);

                    // Преобразуем canvas в Blob
                    canvas.toBlob((blob) => {
                        if (blob) {
                            resolve(blob);
                        } else {
                            reject(new Error('Не удалось преобразовать изображение в Blob.'));
                        }
                    }, file.type);
                } catch (exifError) {
                    console.error('Ошибка обработки EXIF данных:', exifError);
                    reject(new Error('Не удалось обработать EXIF данные.'));
                }
            };

            img.onerror = () => {
                reject(new Error('Не удалось загрузить изображение.'));
            };
        };

        reader.onerror = (error) => {
            reject(new Error('Ошибка чтения файла:', error));
        };
    });
};