import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import MoreVert from '@mui/icons-material/MoreVert';
import { Card, IconButton, Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { deleteComponent, setComponents as addComponentsInBulk, setSelectedComponent, setEditablePlugin } from '../../store';
import {translate} from "../../translations";
import useWebApp from "../../twa/useWebApp";

// Styled components
const FactoryEditor = styled('div')(({ theme, showBorder, themeParams }) => ({
    position: 'relative',
    width: '100%',
    borderRadius: 16,
    borderColor: showBorder ? themeParams.link_color : 'transparent',
    borderStyle: 'dashed',
    borderWidth: 2,
}));

const EditorHeader = styled(Card)(({ theme, showIcons, themeParams }) => ({
    position: 'absolute',
    top: -3,
    right: -3,
    left: 'auto',
    opacity: showIcons ? 1 : 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    backgroundColor: `${themeParams.button_color}AA`,
    backgroundImage: 'none',
    backdropFilter: 'blur(5px)',
    padding: theme.spacing(1),
    borderRadius: '0 12px 0 12px',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
        gap: theme.spacing(2),
    },
}));

const ChildrenContainer = styled('div')({
    width: '100%',
});

const EditWrapperComponent = ({ component, children, themeParams, language, componentId }) => {
    const dispatch = useDispatch();
    const components = useSelector((state) => state.components);
    const selectedComponent = useSelector((state) => state.selectedComponent);
    const [anchorEl, setAnchorEl] = useState(null); // For menu
    const WebApp = useWebApp();
    const accessToken = useSelector(state => state.accessToken);

    const handleMenuClick = (event) => {
        event.stopPropagation(); // Prevent deselecting when clicking the menu button
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        handleMenuClose();
        if (component) {
            dispatch(setSelectedComponent({ id: component.id }));
            dispatch(setEditablePlugin(component));
        }
    };

    const handleSortUp = () => {
        if (component) {
            const resortedComponents = resort(components, { id: component.id, direction: 'up' });

            axios
                .put(`/v1/components/change-sort-indexes`, { sorted_map: resortedComponents }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                .then((response) => {
                    const component = response.data.data;
                })
                .catch((error) => {
                    console.error('Error creating component:', error);
                });
        }
    };

    const resort = (components, addedInfo) => {
        const { id: targetId, direction } = addedInfo;
        let sortedComponents = [...components].sort((a, b) => a.sortIndex - b.sortIndex);
        const targetIndex = sortedComponents.findIndex((component) => component.id === targetId);

        if (targetIndex === -1) return sortedComponents;

        let newIndex = direction === 'up' ? Math.max(targetIndex - 1, 0) : Math.min(targetIndex + 1, sortedComponents.length - 1);

        if (newIndex !== targetIndex) {
            const tempSortIndex = sortedComponents[targetIndex].sortIndex;
            sortedComponents[targetIndex].sortIndex = sortedComponents[newIndex].sortIndex;
            sortedComponents[newIndex].sortIndex = tempSortIndex;
        }

        dispatch(addComponentsInBulk(sortedComponents));

        return sortedComponents.map((component) => ({
            id: component.id,
            index: component.sortIndex,
        }));
    };

    const handleSortDown = () => {
        if (component) {
            const resortedComponents = resort(components, { id: component.id, direction: 'down' });

            axios
                .put(`/v1/components/change-sort-indexes`, { sorted_map: resortedComponents }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                .then((response) => {
                    const component = response.data.data;
                })
                .catch((error) => {
                    console.error('Error creating component:', error);
                });
        }
    };

    const handleDeleteClick = () => {
        handleMenuClose();
        WebApp.showConfirm(translate(language, 'delete_component_warning'), async (agree) => {
            if (agree) {
                deleteComponentHandler();
            }
        });
    };

    const deleteComponentHandler = () => {
        if (component) {
            const componentID = component.id
            axios
                .delete(`/v1/components/${componentID}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })
                .then((response) => {
                    dispatch(deleteComponent(componentID));
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const currentIndex = component ? components.findIndex((c) => c.id === component.id) : -1;
    const isFirstComponent = component && currentIndex === 0;
    const isLastComponent = component && currentIndex === components.length - 1;

    // Determine if this component is selected
    const isSelected = component?.id === selectedComponent?.id;

    return (
        <FactoryEditor
            showBorder={isSelected}
            themeParams={themeParams}
        >
            {(component && isSelected) && (
                <EditorHeader className="editor-header" showIcons={isSelected} themeParams={themeParams}>
                    {!isFirstComponent && <ArrowUpward onClick={handleSortUp} />}
                    {!isLastComponent && <ArrowDownward onClick={handleSortDown} />}
                    <IconButton onClick={handleMenuClick}>
                        <MoreVert />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        PaperProps={{
                            sx: {
                                backgroundColor: themeParams.section_bg_color,
                                borderRadius: '20px',
                            },
                        }}
                    >
                        <MenuItem onClick={handleEditClick}>
                            <Edit style={{ marginRight: 8 }} />
                            {translate(language, 'edit')}
                        </MenuItem>
                        <MenuItem onClick={handleDeleteClick}>
                            <Delete style={{ marginRight: 8 }} />
                            {translate(language, 'delete')}
                        </MenuItem>
                    </Menu>
                </EditorHeader>
            )}
            <ChildrenContainer>{children}</ChildrenContainer>
        </FactoryEditor>
    );
};

export default EditWrapperComponent;