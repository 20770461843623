import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import { Typography, Box, Stepper, Step, StepLabel, StepContent, Chip } from '@mui/material';
import useWebApp from "../twa/useWebApp";
import CourseCard from './CourseCard';
import StepCard from './StepCard';
import SkeletonCourse from "./SkeletonCourse";
import { styled } from '@mui/system';
import CourseEditModal from './CourseEditModal'; // Импортируем новый компонент модального окна
import StepEditModal from './StepEditModal'; // Импортируем новый компонент модального окна
import {setAccessToken, setSelectedCourse, setSelectedStep} from "../store";
import { StepConnector } from '@mui/material';
import NewStepItem from './NewStepItem';
import StepIconComponent from './StepIconComponent';
import {translate} from "../translations";
import { Breadcrumbs } from '@telegram-apps/telegram-ui';
import { truncateText } from '../helper';

// Define a custom connector with dashed and thicker lines
const CustomConnector = styled(StepConnector)(({ themeParams }) => ({
}));

// Update your CourseDetails component
const CourseDetails = ({ themeParams, language }) => {
    const navigate = useNavigate();
    const { courseId } = useParams();
    const [loading, setLoading] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isStepEditModalOpen, setIsStepEditModalOpen] = useState(false);
    const WebApp = useWebApp();
    const accessToken = useSelector(state => state.accessToken);
    const course = useSelector(state => state.selectedCourse);
    const dispatch = useDispatch();
    const selectedStep = useSelector(state => state.selectedStep) || {id: courseId, type: 'course'};

    const handleMoveUp = (index) => {
        if (index > 0) {
            const updatedSteps = [...course.steps];
            const temp = updatedSteps[index - 1];
            updatedSteps[index - 1] = updatedSteps[index];
            updatedSteps[index] = temp;

            const stepsWithUpdatedNumbers = updatedSteps.map((step, idx) => ({
                ...step,
                step_number: idx
            }));

            updateCourseSteps(stepsWithUpdatedNumbers);
        }
    };

    const handleMoveDown = (index) => {
        if (index < course.steps.length - 1) {
            const updatedSteps = [...course.steps];
            const temp = updatedSteps[index + 1];
            updatedSteps[index + 1] = updatedSteps[index];
            updatedSteps[index] = temp;

            const stepsWithUpdatedNumbers = updatedSteps.map((step, idx) => ({
                ...step,
                step_number: idx
            }));

            updateCourseSteps(stepsWithUpdatedNumbers);
        }
    };

    const updateCourseSteps = (updatedSteps) => {
        const updatedCourse = {...course, steps: updatedSteps};
        dispatch(setSelectedCourse(updatedCourse));  // предполагается, что у вас есть такое действие

        const stepNumbers = updatedSteps.map((step, index) => ({
            id: step.id,
            step_number: index
        }));

        sendUpdatedStepNumbers(stepNumbers);
    };

    const sendUpdatedStepNumbers = async (stepNumbers) => {
        try {
            await axios.put('v1/steps/numbers', {step_numbers: stepNumbers}, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error("Error updating step numbers:", error);
        }
    };

    const handleStepEdit = (step) => {
        dispatch(setSelectedStep({ id: step.id, type: "step", entity: step }));
        setIsStepEditModalOpen(true);
    }

    const handleDelete = (step) => {
        WebApp.showConfirm(translate(language, 'delete_step_warning'), async (agree) => {
            if (agree) {
                try {
                    await axios.delete(`/v1/steps/${step.id}`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`
                        }
                    }).then(() => {
                        const updatedCourse = {
                            ...course,
                            steps: course.steps.filter(s => s.id !== step.id)
                        };
                        dispatch(setSelectedCourse(updatedCourse));
                        dispatch(setSelectedStep({
                            id: course.id,
                            type: "course",
                        }))
                    }).catch(error => {
                            console.error('Ошибка при удалении шага:', error);
                    })
                } catch (error) {
                    console.error("Ошибка при удалении шага:", error);
                }
            }
        });
    };

    const handleStepClick = (id, type, step) => () => {
        dispatch(setSelectedStep({ id: id, type: type, entity: step }));
    };

    const handleCardStepClick = (step) => () => {
        if (step.type !== 'invoice') {
            navigate(`/courses/${course.id}/steps/${step.id}`);
        } else {
            handleStepEdit(step);
        }
    }

    const handleBackClick = useCallback(() => {
        navigate(`/`);
        if (WebApp) {
            WebApp.BackButton.hide();
            WebApp.MainButton.hide();
        }
    }, [WebApp]);

    const CBBox = styled(Box)({
        padding: '20px',
    });

    const CBStepLabel = styled(StepLabel)({
        color: themeParams.section_header_text_color,
        fontSize: '0.80rem',
        textAlign: 'left',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
    });

    useEffect(() => {
        window.scrollTo(0, 0);

        dispatch(setSelectedStep({ id: courseId, type: 'course' }));

        if (WebApp) WebApp.BackButton.show();
        if (WebApp) {
            WebApp.BackButton.onClick(handleBackClick);
        }
    }, [WebApp, handleBackClick]);

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await axios.get(`/v1/courses/${courseId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                dispatch(setSelectedCourse(response.data.data));
            } catch (error) {
                console.error('Error fetching course details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourse();
    }, [courseId, accessToken]);

    const handleCardClick = () => {
        setIsEditModalOpen(true);
    };

    const handleEditModalClose = () => {
        setIsEditModalOpen(false);
    };

    const handleStepEditModalClose = () => {
        setIsStepEditModalOpen(false);
    };

    const handleEditModalOpenChange = (open) => {
        setIsEditModalOpen(open);
    };

    const handleStepEditModalOpenChange = (open) => {
        setIsStepEditModalOpen(open);
    };

    if (loading) return <SkeletonCourse themeParams={themeParams} />;
    if (!course) return <Typography>No course details available</Typography>;

    return (
        <div>
            <Box sx={{
                padding: '20px',
                backgroundColor: themeParams.background_color,
                color: themeParams.text_color
            }}>
                <Breadcrumbs divider="chevron">
                    <Box onClick={handleBackClick} sx={{
                        fontSize: '0.80rem',
                        marginRight: '10px',
                        color: themeParams.section_header_text_color,
                    }}>
                        {translate(language, 'courses_br')}
                    </Box>
                    <Box sx={{
                        marginLeft: '10px',
                        fontSize: '0.80rem',
                        color: themeParams.section_header_text_color,
                    }}>
                        {truncateText(course.title, 15)}
                    </Box>
                </Breadcrumbs>
            </Box>
            <CBBox>
                <Stepper
                    orientation="vertical"
                    connector={<CustomConnector themeParams={themeParams} />} // Use custom connector
                >
                    <Step key={course.id} active={course.id === selectedStep.id && selectedStep.type === "course"}  completed={course.id === selectedStep.id && selectedStep.type === "course"} >
                        <CBStepLabel
                            StepIconComponent={() => <StepIconComponent themeParams={themeParams} isCompleted={course.id === selectedStep.id && selectedStep.type === "course"} type="course" />}
                            style={{ cursor: 'pointer' }} onClick={handleStepClick(course.id, "course")}>
                            {course.title}
                        </CBStepLabel>
                        <StepContent>
                            <div >
                                <CourseCard themeParams={themeParams} handleCardClick={handleCardClick} />
                            </div>
                        </StepContent>
                    </Step>

                    {/* Steps */}
                    {course.steps?.map((step, index) => (
                        <Step key={step.id} active={step.id === selectedStep.id && selectedStep.type === "step"}  completed={step.id === selectedStep.id && selectedStep.type === "step"} >
                            <CBStepLabel
                                StepIconComponent={() => <StepIconComponent themeParams={themeParams} isCompleted={step.id === selectedStep.id && selectedStep.type === "step"} type={step.type} />}
                                style={{ cursor: 'pointer' }} onClick={handleStepClick(step.id, "step", step)}>
                                {step.name || 'Step'}
                            </CBStepLabel>
                            <StepContent>
                                <StepCard
                                    onMoveUp={handleMoveUp}
                                    onMoveDown={handleMoveDown}
                                    onDelete={handleDelete}
                                    onEdit={handleStepEdit}
                                    index={index}
                                    course={course}
                                    handleCardClick={handleCardStepClick(step)}
                                    themeParams={themeParams}
                                    step={step}
                                    language={language}
                                />
                                <Box pt={2}>
                                    {step.status === 'public' && (
                                        <Chip
                                            label={translate(language, 'public')}
                                            color="success"
                                            size="small"
                                            sx={{marginRight:'10px'}}
                                        />
                                    )}
                                    {step.status === 'draft' && (
                                        <Chip label={translate(language, 'draft')} size="small" sx={{marginRight:'10px'}} />
                                    )}
                                    <Chip
                                        label={step.type === 'invoice' ? translate(language, 'invoice') + ' ⭐' : translate(language, step.type)}
                                        size="small"
                                    />
                                    {step.is_collection &&
                                        <Chip sx={{marginLeft:'10px'}} size="small" onClick={handleCardStepClick(step)} label={translate(language, 'content')}></Chip>}
                                </Box>
                            </StepContent>
                        </Step>
                    ))}

                    {/* new step */}
                    <Step>
                        <StepLabel StepIconComponent={() => <StepIconComponent type="add" themeParams={themeParams} isCompleted={false} />}>
                            <NewStepItem themeParams={themeParams} language={language} />
                        </StepLabel>
                    </Step>

                </Stepper>
            </CBBox>

            {/* Edit Modal */}
            <CourseEditModal
                language={language}
                themeParams={themeParams}
                open={isEditModalOpen}
                onClose={handleEditModalClose}
                openChange={handleEditModalOpenChange}
            />

            {/* Edit Step Modal */}
            { (selectedStep.entity && selectedStep.type === "step" ) ? (
                <StepEditModal
                    step={selectedStep.entity}
                    language={language}
                    themeParams={themeParams}
                    open={isStepEditModalOpen}
                    onClose={handleStepEditModalClose}
                    openChange={handleStepEditModalOpenChange}
                />
            ):''}

        </div>
    );
};

export default CourseDetails;