import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Typography,
    ListItem,
    ListItemText,
    CircularProgress, Box, Chip,
} from '@mui/material';
import useWebApp from "./twa/useWebApp";
import { translate } from './translations';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedAssignment } from './store';
import { List as TList, AvatarStack, Avatar as TAvatar, Section, Cell, Badge as TBadge } from '@telegram-apps/telegram-ui';
import {useNavigate} from "react-router-dom";

const Assignments = ({ themeParams, language, mode = 'light' }) => {
    const navigate = useNavigate();
    const [assignments, setAssignments] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [loading, setLoading] = useState(true);

    const WebApp = useWebApp();
    const dispatch = useDispatch();
    const selectedAssignment = useSelector(state => state.selectedAssignment);
    const token = useSelector(state => state.accessToken);

    const fetchAssignments = async () => {
        try {
            const response = await axios.get(`/v1/assignments`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setAssignments(response.data.data);
            setLoading(false);
        } catch (error) {
            console.error("Ошибка при загрузке заданий:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!token) {
            setLoading(false);
            return;
        }

        fetchAssignments();
    }, []);

    useEffect(() => {
        if (selectedAssignment && !selectedComponent) {
            setSelectedComponent(selectedAssignment.components[0]);
        }
    }, [selectedAssignment, selectedComponent]);

    const handleCardClick = useCallback((assignment) => {
        dispatch(setSelectedAssignment(assignment));
        setSelectedComponent(assignment.components[0]);
        navigate(`/assignments/${assignment.id}`);
    }, [WebApp, dispatch, navigate]);

    // Helper function to sort assignments by creation date
    const sortByDate = (a, b) => new Date(b.components[0].created_at) - new Date(a.components[0].created_at);

    // Group and sort assignments
    const pendingAssignments = assignments
        ?.filter(assignment => assignment.status === 'pending')
        ?.sort(sortByDate);

    const otherAssignments = assignments
        ?.filter(assignment => assignment.status === 'rejected' || assignment.status === 'approved')
        ?.sort(sortByDate);

    const statusColors = (status) => {
        if (status === 'approved') {
            return '#A8E4A0';
        } else if (status === 'rejected') {
            return themeParams.destructive_text_color;
        } else {
            return themeParams.hint_color;
        }
    }

    return (
        <Box sx={{
            backgroundColor: themeParams.background_color,
            color: themeParams.text_color
        }}>
            <TList>
                {/* Section for pending assignments */}
                {pendingAssignments?.length > 0 && (
                    <Section header={translate(language, 'pending_assignments')}>
                        {pendingAssignments.map(assignment => (
                            <Cell key={assignment.id} status={assignment.status}
                                  onClick={() => handleCardClick(assignment)}
                                  subtitle={
                                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                              {/*<PendingIcon style={{ color: themeParams.hint_color }} />*/}
                                              <Chip sx={{ marginLeft: '10px', backgroundColor: 'var(--tgui--secondary_fill)', color:themeParams.subtitle_text_color  }} label={translate(language, assignment.status)} />
                                          </Box>
                                          <Typography variant="body2" sx={{ color: themeParams.subtitle_text_color }}>
                                              {new Date(assignment.components[0].created_at).toLocaleString()}
                                          </Typography>
                                      </Box>
                                  }
                            >
                                <ListItem>
                                    <Box sx={{ marginRight: '15px' }}>
                                        <AvatarStack>
                                            <TAvatar src={assignment.course?.image_url} size={48} />
                                            <TAvatar src={assignment.user.avatar} size={48} />
                                        </AvatarStack>
                                    </Box>
                                    <ListItemText
                                        primary={<Typography variant="body1" noWrap>{assignment.user.display_name}</Typography>}
                                        secondary={
                                            <Typography variant="body2" sx={{ color: themeParams.subtitle_text_color }}>
                                                {assignment.course?.title}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </Cell>
                        ))}
                    </Section>
                )}

                {/* Section for approved assignments */}
                {otherAssignments?.length > 0 && (
                    <Section header={translate(language, 'all_assignments')}>
                        {otherAssignments.map(assignment => (
                            <Cell key={assignment.id} status={assignment.status}
                                  onClick={() => handleCardClick(assignment)}
                                  subtitle={
                                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
{/*                                              {assignment.status === 'approved' &&
                                                  <ThumbUpIcon style={{ color: '#A8E4A0' }} />}
                                              {assignment.status === 'rejected' &&
                                                  <ThumbDownIcon style={{ color: themeParams.destructive_text_color }} />}*/}
                                              <Chip sx={{ marginLeft: '10px', backgroundColor: 'var(--tgui--secondary_fill)', color:statusColors(assignment.status) }} label={translate(language, assignment.status)} />
                                          </Box>
                                          <Typography variant="body2" sx={{ color: themeParams.subtitle_text_color }}>
                                              {new Date(assignment.components[0].created_at).toLocaleString()}
                                          </Typography>
                                      </Box>
                                  }
                            >
                                <ListItem>
                                    <Box sx={{ marginRight: '15px' }}>
                                        <AvatarStack>
                                            <TAvatar src={assignment.course?.image_url} size={48} />
                                            <TAvatar src={assignment.user.avatar} size={48} />
                                        </AvatarStack>
                                    </Box>
                                    <ListItemText
                                        primary={<Typography variant="body1" noWrap>{assignment.user.display_name}</Typography>}
                                        secondary={
                                            <Typography variant="body2" sx={{ color: themeParams.subtitle_text_color }}>
                                                {assignment.course?.title}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </Cell>
                        ))}
                    </Section>
                )}
            </TList>

            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (assignments == null || assignments?.length === 0) && (
                <Typography variant="body2" color="textSecondary" align="center" marginY={2}>
                    {translate(language, 'no_assignments')}
                </Typography>
            )}
        </Box>
    );
};

export default Assignments;