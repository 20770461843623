export const BXStyles = (
    viewerWidthPercent,
    buttonColor = '#4c56b2',
    buttonBackgroundColor = '#5e6bdf',
    textColor = '#2f2e2f',
    backgroundColor = '#fff',
    hintColor = '#919091',
    linkColor = '#4c56b2',
    bxButtonColor = '#3A205B',
    bxButtonTextColor = '#fff',
    secondaryBackgroundColor = '#f8f8f8',
) => `

@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
    
:root {
  --bx-text-color: ${textColor};
  --bx-background-color: ${backgroundColor};
  --bx-hint-color: ${hintColor};
  --bx-link-color: ${linkColor};
  --bx-button-color: ${bxButtonColor};
  --bx-button-text-color: ${bxButtonTextColor};
  --bx-secondary-background-color: ${secondaryBackgroundColor};
}

        .bx-form-bottom {
            text-size-adjust: 100%;
            color: var(--bx-text-color);
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            box-sizing: border-box;
            margin: 0 -10px;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
        }

@media (max-width: 640px) { /* Порог для мобильной версии, обычно 640px или меньше */
    .bx_viewer {
        width: 100%; /* На мобильных устройствах используйте полную ширину */
        margin: auto;
    }

    .bx_viewer .bx_viewer_block img {
        width: 100%;
        height: auto;
    }

    .bx-btn-blue-full, .bx-btn-blue, .bx-btn-purpl, .bx-btn-white {
        padding: 0 15px; /* Меньше отступ для мобильных устройств */
        font-size: 14px; /* Меньший размер шрифта для мобильных устройств */
    }

    .bx-form-input, .bx-form-textarea {
        padding: 8px 15px; /* Меньше отступ для мобильных устройств */
    }

    .bx-plug-wrapper {
        padding: 25px; /* Меньше отступ для мобильных устройств */
    }

    /* Дополнительные стили для мобильных устройств */
}


html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-family: Montserrat, sans-serif;
  color: var(--bx-text-color);
}

body {
  margin: 0;
}

.bx_viewer {
    width: ${viewerWidthPercent}%; /* или конкретная ширина, например, 500px */
    margin: auto; /* для центрирования */
}

.bx_viewer .bx_viewer_block img {
    width: 100%; /* делает изображение адаптивным в рамках bx_viewer */
    height: auto; /* сохраняет пропорции изображения */
}

b, strong {
  font-weight: bolder
}

code, kbd, pre, samp {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em
}

.block {
    display: block
}

.circle-loader {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top-color: var(--bx-button-text-color);
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

 .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 100%; /* Адаптируем высоту под размер кнопки */
  vertical-align: middle; /* Выравнивание по вертикали */
}

 .lds-ellipsis div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff; /* Цвет элементов лоадера */
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

 .lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}

 .lds-ellipsis div:nth-child(2) {
  left: 14px;
  animation: lds-ellipsis2 0.6s infinite;
}

 .lds-ellipsis div:nth-child(3) {
  left: 24px;
  animation: lds-ellipsis2 0.6s infinite;
}

 .lds-ellipsis div:nth-child(4) {
  left: 34px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: translateY(-50%) scale(0.5);
  }
  100% {
    transform: translateY(-50%) scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: translateY(-50%) scale(1);
  }
  100% {
    transform: translateY(-50%) scale(0.5);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translateY(-50%) translateX(0);
  }
  100% {
    transform: translateY(-50%) translateX(8px); /* Уменьшено расстояние перемещения */
  }
}

.lds-ellipsis div {
  animation-timing-function: ease-in-out; /* Для более плавной анимации */
}

.language-selector {
    position: relative;
    display: inline-block;
}

.language-selector button {
    display: flex;
    align-items: center;
    background-color: var(--bx-secondary-background-color);
    border: 1px solid var(--bx-text-color);
    border-radius: 0.25rem; /* rounded */
    padding: 0.5rem 0.75rem 0.5rem 0.5rem; /* p-2 pl-3 pr-1 */
    cursor: pointer;
    color: var(--bx-text-color);
}

.language-selector button:focus {
    outline: none;
    border-color: var(--bx-button-color);
}

.dropdown {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /* shadow-md */
    border-radius: 0.25rem; /* rounded */
    width: 10rem; /* w-40 */
    padding-top: 0.25rem; /* py-1 */
    padding-bottom: 0.25rem;
}

.dropdown li {
    padding: 0.25rem 0.75rem;
    cursor: pointer;
}

.dropdown li:hover {
    background-color: #edf2f7; /* hover:bg-gray-100 */
}

.selected-language {
    font-weight: bold; /* font-semibold */
    color: #9f7aea; /* text-purple */
}

.arrow-up, .arrow-down {
    /* Стили для стрелок */
}

.bx_viewer {
  padding-top: 20px !important;
}

.bx-btn-blue {
  /* Existing styles */
  background-color: var(--bx-button-color);
}

.bx-btn-blue:focus {
  outline: none;
}

.bx-btn-blue.disabled,
.bx-btn-blue:disabled {
  background-color: #e3e3e3;
  cursor: not-allowed;
  opacity: 0.5;
}

.bx-btn-blue.disabled:hover {
  background-color: #e3e3e3;
}

.bx-btn-blue {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  width: 100%;
  overflow: visible;
  text-transform: none;
  display: block;
  border: none;
  text-align: center;
  height: 50px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  padding: 0 20px;
  transition: background-color .15s ease 0s;
  color: var(--bx-button-text-color); 
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button
}

.bx-btn-blue:hover {
  background-color: var(--bx-button-color);
}

.bx-btn-blue:focus {
  outline: none
}

.bx-btn-blue-full {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  width: 100%;
  overflow: visible;
  text-transform: none;
  display: block;
  border: none;
  text-align: center;
  height: 40px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  padding: 0 20px;
  transition: background-color .15s ease 0s;
  color: var(--bx-button-text-color); 
  background-color: var(--bx-button-color);
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button
}

.bx-btn-blue-full:hover {
  background-color: var(--bx-button-color); /* Использование переменной для цвета фона при наведении */
}

.bx-btn-blue-full:focus {
  outline: none;
}

.buttonload {
  background-color: var(--bx-button-color);
  color: var(--bx-button-text-color);
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 16px;
  width:100%;
  cursor: pointer;
}

.emptyButton {
  padding: 12px 24px;
 }

.fa {
  margin-left: 10px;
  margin-right: 18px;
  
}
section.loaders .loader {
  display: inline-block;
}
.loader {
  border: 16px solid var(--bx-button-text-color);;
  border-top: 16px solid var(--bx-button-text-color);;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 25px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
.colors2 {
  border-bottom: 16px solid blue;
}
.colors3 {
  border-bottom: 16px solid red;
  border-right: 16px solid green;
}
.colors4 {
  border-bottom: 16px solid red;
  border-right: 16px solid green;
  border-left: 16px solid pink;
}

.bx-btn-purpl {
   text-size-adjust: 100%;
   box-sizing: border-box;
   font-family: inherit;
   margin: 0px;
   overflow: visible;
   text-transform: none;
   appearance: button;
   display: block;
   border: none;
   text-align: center;
   height: 40px;
   border-radius: 12px;
   font-weight: 600;
   font-size: 16px;
   line-height: 14px;
   touch-action: manipulation;
   cursor: pointer;
   white-space: nowrap;
   outline: none;
   padding: 0px 20px;
   transition: background-color 0.15s ease 0s;
   color: rgb(244, 237, 252);
   background-color: rgb(147, 81, 230);
   margin-left: auto;
}
    
.bx-btn-purpl:hover {
   background-color: rgb(117, 64, 184);
}

.bx-btn-white {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
  display: block;
  text-align: center;
  height: 40px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  padding: 0 20px;
  transition: background-color .15s ease 0s;
  border: 1px solid #e3e3e3;
  color: #2f2e2f;
  background-color: #fff;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button
}

.bx-btn-white:hover {
  background-color: #f1f1f1
}

.bx-btn-white:active {
  background-color: #e3e3e3
}

.bx-btn-white:focus {
  outline: none
}

.bx-form-input {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  background-color: var(--bx-secondary-background-color);
  border-radius: 12px;
  height: 40px;
  padding: 8px 20px;
  color: var(--bx-text-color);
  border: 1px solid var(--bx-hint-color);
  font-weight: 500;
  transition: box-shadow .15s ease-out 0s;
  width: 100%
}

.bx-form-input:hover {
  box-shadow: 0 0 1px 1px rgb(200 199 200)
}

.bx-form-input:focus {
  border: 1px solid var(--bx-button-color);
  box-shadow: 0 0 1px 1px var(--bx-button-color);
  background-color: var(--bx-secondary-background-color);
  outline: none
}

.bx-form-textarea {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  overflow: auto;
  resize: none;
  background-color: var(--bx-secondary-background-color);
  border-radius: 12px;
  padding: 8px 18px;
  color: var(--bx-text-color);
  border: 1px solid var(--bx-hint-color) 
  font-weight: 500;
  width: 100%;
  line-height: 24px;
  transition: box-shadow .15s ease-out 0s
}

.bx-form-textarea:hover {
  box-shadow: 0 0 1px 1px var(--bx-hint-color)
}

.bx-form-textarea:focus {
  box-shadow: 0 0 1px 1px var(--bx-button-color);;
  background-color: var(--bx-secondary-background-color);
  outline: none
}

.bx-form-label {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-family: Montserrat, sans-serif;
  box-sizing: border-box;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-left: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: var(--bx-hint-color);
}

.rounded {
    border-radius: 12px
}

.rounded-lg {
    border-radius: 20px
}

.rounded-full {
    border-radius: 9999px
}

.rounded-b-lg {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px
}

.rounded-tl-lg {
    border-top-left-radius: 20px
}

.rounded-tr-lg {
    border-top-right-radius: 20px
}

.border {
    border-width: 1px
}

.border-b-2 {
    border-bottom-width: 2px
}

.border-r {
    border-right-width: 1px
}

.border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 241, 241, var(--tw-border-opacity))
}

.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(202, 203, 207, var(--tw-border-opacity))
}

        .bx-success-alert {
            text-size-adjust: 100%;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            box-sizing: border-box;
            padding: 20px;
            color: rgb(19, 103, 16);
            background-color: rgb(244, 251, 243);
            border-radius: 12px;
            line-height: 22px;
        }
        .bx-wrong-alert {
            text-size-adjust: 100%;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            box-sizing: border-box;
            padding: 20px;
            color: rgb(136, 44, 44);
            background-color: rgb(252, 237, 237);
            border-radius: 12px;
            line-height: 22px;
        }
        .decline-feedback {
            text-size-adjust: 100%;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            color: rgb(136, 44, 44);
            line-height: 22px;
            box-sizing: border-box;
        }
        .info-feedback {
            text-size-adjust: 100%;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            color: rgb(80, 194, 255);
            line-height: 22px;
            box-sizing: border-box;
        }
        .bx-info-alert {
            text-size-adjust: 100%;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            box-sizing: border-box;
            padding: 20px;
            color: rgb(13, 60, 97);
            background-color: rgb(235, 248, 255);
            border-radius: 12px;
            line-height: 22px;
        }
        
        .bx-feedback-alert {
            text-size-adjust: 100%;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            box-sizing: border-box;
            padding: 20px;
            color: rgb(190, 141, 69);
            background-color: rgb(255, 249, 196);
            border-radius: 12px;
            line-height: 22px;
        }
        
         a {
            color: var(--bx-link-color);
            text-decoration: underline;
            font-weight: 500
         }

        .bx-settings-btn svg, .bx-close-btn svg {
            stroke: var(--bx-text-color); /* Для изменения цвета линий SVG */
            fill: var(--bx-text-color); /* Для заполнения SVG, если требуется */
        }
        
        .bx-settings-btn, .bx-close-btn {
            border: none;
            background: none;
            cursor: pointer;
            font-size: 24px;  
            display: flex;
            align-items: right;
            color: var(--bx-text-color);
        }

        .bx-action-buttons {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .bx-plug-wrapper {
            text-size-adjust: 100%;
            color: var(--bx-text-color);
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            box-sizing: border-box;
            border-radius: 20px;
            width: 100%;
            padding: 10px 10px 0
        }
        
        .bx-form-title img {
            width: 24px;
            height: 24px;
            margin-right: 0px;
            border-radius: 50%; /* Делаем изображение круглым */
            background-color: #ffffff; /* Белый фон */
            object-fit: cover; /* Изображение будет масштабироваться, сохраняя пропорции внутри круга */
            padding: 2px; /* Небольшое отступление от края, чтобы фон был виден */
        }
                
        .bx-form-title {
            text-size-adjust: 100%;
            color: var(--bx-text-color);
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            box-sizing: border-box;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            margin-left: 10px;
        }
        
        .bx-form-top {
            cursor: pointer;
            margin-bottom: 21px;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: justify;
            -moz-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -moz-box-align: center;
            align-items: center;
        }

        .bx-plug-name {
            text-size-adjust: 100%;
            font-family: Montserrat, sans-serif;
            box-sizing: border-box;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--bx-text-color);
            margin: 0 0 0 12px
        }
        
        .bx_form_content {
            padding-bottom: 25px;
        }  
        
`;
