import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import {styled} from '@mui/system';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/HistoryEdu';
import AssessmentIcon from '@mui/icons-material/PlaylistAddCheck';
import BotIcon from '@mui/icons-material/Power'; // Иконка для ботов
import PaymentIcon from '@mui/icons-material/StarBorder';  // Иконка для платежей
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import useWebApp from './twa/useWebApp';
import Courses from './course/Main';
import {useSelector, useDispatch} from 'react-redux';
import {setSelectedAssignment, setTab} from "./store";
import '@telegram-apps/telegram-ui/dist/styles.css';
import { Route, Routes } from 'react-router-dom';
import CourseDetails from './course/CourseDetails';
import { setAccessToken } from "./store";
import StepContent from "./course/content/StepContent";
import Assignments from "./Assignments";
import AssignmentDetails from "./AssignmentDetails";
import Bots from "./Bots";  // Новый компонент для списка ботов
import Payments from "./Payments";  // Новый компонент для списка платежей
import Students from "./Students";  // Новый компонент для списка студентов
import './App.css';
import {translate} from "./translations";

axios.defaults.baseURL = "https://api.coob.app";
axios.defaults.withCredentials = true;

export default function MyApp() {
    const location = useLocation();
    const navigate = useNavigate();
    const correctedSearch = location.search.replace(/&amp;/g, '&');
    const queryParams = new URLSearchParams(correctedSearch);

    const showNavigation = useSelector(state => state.showNavigation)

    const tab = queryParams.get('tab') || "main";

    const [loading, setLoading] = useState(true);
    const [activeTime, setActiveTime] = useState(null);

    const dispatch = useDispatch();
    const value = useSelector(state => state.tab);

    let WebApp = useWebApp();

    const [dark, setDark] = useState(false);
    const [user, setUser] = useState(null);
    const themeParams = WebApp.themeParams || {};

    const [language, setLanguage] = useState('en');

    useEffect(() => {
        if (WebApp) {
            WebApp.setHeaderColor('secondary_bg_color');
            if (WebApp.platform !== "macos") {
                WebApp.enableClosingConfirmation();
            }

            WebApp.disableVerticalSwipes();
            WebApp.onEvent('viewportChanged', () => WebApp.expand());
        }
    }, [WebApp]);

    useEffect(() => {
        if (WebApp) {
            WebApp.ready();
            setLoading(true);

            const tabMapping = {
                'assignments': 1,
                'bots': 2,
                'profile': 3,
                'payments': 4,
                'students': 5,
                'main': 0
            };

            const initialTab = tabMapping[tab] !== undefined ? tabMapping[tab] : 0;
            dispatch(setTab(initialTab));

            const colorScheme = WebApp.colorScheme || 'light';
            setDark(colorScheme === 'dark');

            axios.post("/v1/auth/login/telegram-app", {
                web_app_data: WebApp.initData,
            }, {
                withCredentials: true,
            }).then(response => {
                const token = response.data.data.access_token;
                dispatch(setAccessToken(token));
                setUser(response.data.data.user);
                setLanguage(response.data.data.user.language || 'en');
                setLoading(false);

                if (!WebApp.isExpanded) {
                    WebApp.expand();
                }
            }).catch(error => {
                WebApp = null;
                console.error("Error:", error);
                setLoading(false);
            });
        }
    }, [WebApp, tab, dispatch]);

    const StyledContainer = styled('div')({
        backgroundColor: themeParams.secondary_bg_color,
        color: themeParams.text_color,
        backgroundImage: 'none',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: '70px',
    });

    const StyledBottomNavigation = styled(BottomNavigation)(({theme}) => ({
        backgroundColor: `${themeParams.secondary_bg_color} !important`,
        color: themeParams.text_color,
        position: 'fixed',
        bottom: 0,
        height: '70px',
        paddingBottom: '16px',
        width: '100%',
        boxShadow: theme.shadows[4],
        zIndex: 1000,
        '& .Mui-selected': {
            color: `${themeParams.button_color} !important`,
        },
        '& .MuiBottomNavigationAction-label': {
            color: themeParams.subtitle_text_color,
            fontSize: '10px',
        },
        display: showNavigation ? 'flex' : 'none',
    }));

    if (!WebApp) {
        return null;
    }

    const theme = createTheme({
        palette: {
            mode: dark ? 'dark' : 'light',
        },
    });

    const renderPage = () => {
        return (
            <Routes>
                <Route path="/" element={<Courses themeParams={themeParams} language={language} />} />
                <Route path="/courses/:courseId" element={<CourseDetails themeParams={themeParams} language={language} />} />
                <Route path="/courses/:courseId/steps/:stepId" element={<StepContent themeParams={themeParams} language={language} dark={dark} />} />
                <Route path="/assignments" element={<Assignments themeParams={themeParams} language={language} mode={dark ? 'dark' : 'light'} />} />
                <Route path="/assignments/:id" element={<AssignmentDetails themeParams={themeParams} language={language} mode={dark ? 'dark' : 'light'} />} />
                <Route path="/bots" element={<Bots themeParams={themeParams} language={language} />} />
                <Route path="/payments" element={<Payments themeParams={themeParams} language={language} />} />
                <Route path="/students" element={<Students themeParams={themeParams} language={language} />} />
            </Routes>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <StyledContainer>
                <div style={{flex: 1}}>
                    {renderPage()}
                </div>
                <StyledBottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setActiveTime(new Date());
                        dispatch(setTab(newValue));
                        dispatch(setSelectedAssignment(null));
                        window.scrollTo(0, 0);

                        switch (newValue) {
                            case 0:
                                navigate('/');
                                break;
                            case 1:
                                navigate('/assignments');
                                break;
                            case 2:
                                navigate('/bots');  // Переход на раздел ботов
                                break;
                            case 3:
                                navigate('/profile');
                                break;
                            case 4:
                                navigate('/payments');  // Переход на раздел платежей
                                break;
                            default:
                                navigate('/');
                        }
                    }}
                >
                    <BottomNavigationAction label={translate(language, 'courses_menu')} icon={<HomeIcon sx={{fontSize: 28}}/>} />
                    <BottomNavigationAction label={translate(language, 'assignments_menu')} icon={<AssessmentIcon sx={{fontSize: 28}}/>} />
                    {/* <BottomNavigationAction icon={<PaymentIcon sx={{fontSize: 28}}/>} />  Новый раздел "Payments" */}
                    <BottomNavigationAction label={translate(language, 'bots_menu')} icon={<BotIcon sx={{fontSize: 28}}/>} /> {/* Новый раздел "Bots" */}
                    {user ? (
                        <BottomNavigationAction
                            label={user.full_name.substring(0, 10)+'...'}
                            icon={
                                <Avatar
                                    alt={user.full_name.substring(0, 5)}
                                    src={user.avatar}
                                    sx={{width: 28, height: 28, margin: '0 auto 0px'}}
                                />
                            }
                        />
                    ) : (
                        <BottomNavigationAction label={translate(language, 'profile_menu')} icon={<PersonIcon sx={{fontSize: 28}}/>} />
                    )}
                </StyledBottomNavigation>
            </StyledContainer>
        </ThemeProvider>
    );
}