import React, { useState } from 'react';
import axios from 'axios';
import { Box, styled, Tooltip, CircularProgress, Menu, MenuItem, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStep, setSelectedCourse } from "../store";
import {translate} from "../translations";

// Styled component for the Add Button
const AddButton = styled(IconButton)(({ themeParams }) => ({
    position: 'absolute',
    backgroundColor: `${themeParams.secondary_bg_color}AA`,
    backdropFilter: 'blur(5px)',
    '&:hover': {
        backgroundColor: `${themeParams.secondary_bg_color}CC`,
    },
    borderRadius: '50%',
    width: '48px',
    height: '48px',
}));

function NewStepItem({ themeParams, language }) {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStepType, setLoadingStepType] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null); // For controlling the context menu
    const course = useSelector(state => state.selectedCourse);
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.accessToken);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const createStep = async (stepType) => {
        if (isLoading) return;
        setIsLoading(true);
        setLoadingStepType(stepType);

        try {
            const stepData = {
                course_id: course.id,
                text: '',
                payload: null,
                type: stepType,
                is_collection: stepType === 'collection',
                name: '',
                step_number: course.steps ? course.steps.length + 1 : 0,
            };

            const response = await axios.post('v1/steps', stepData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            const newStep = response.data.data;
            const updatedCourse = {
                ...course,
                steps: course.steps ? [...course.steps, newStep] : [newStep]
            };

            dispatch(setSelectedCourse(updatedCourse));
            dispatch(setSelectedStep({
                id: newStep.id,
                type: "step",
                entity: newStep
            }));

        } catch (error) {
            console.error("Error creating step:", error);
        } finally {
            setIsLoading(false);
            setLoadingStepType(null);
            handleMenuClose(); // Close menu after creating the step
        }
    };

    return (
        <Box
            sx={{
                padding: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                borderRadius: '20px',

                borderColor: themeParams.hint_color,
                borderStyle: 'dashed',
                borderWidth: 1,

                backgroundColor: themeParams.secondary_bg_color,
                transition: 'background-color 0.3s ease-in-out',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                position: 'relative',
            }}
        >
            {/* Add button in the center */}
            <AddButton
                onClick={handleMenuOpen}
                themeParams={themeParams}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: `${themeParams.link_color}AA`,
                    color: themeParams.button_text_color,
                    backgroundImage: 'none',
                    backdropFilter: 'blur(5px)',
                    '&:hover': {
                        backgroundColor: `${themeParams.link_color}CC`,
                    },
                }}
            >
                <AddIcon />
            </AddButton>

            {/* Context menu with content options */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{
                    style: {
                        backgroundColor: themeParams.section_bg_color,
                        borderRadius: '20px',
                    },
                }}
            >
                <MenuItem onClick={() => createStep('text')}>
                    {isLoading && loadingStepType === 'text' ? (
                        <CircularProgress size={24} sx={{ marginRight: 1 }} />
                    ) : (
                        <DescriptionIcon sx={{ marginRight: 1 }} />
                    )}
                    {translate(language, "add_text")}
                </MenuItem>
                <MenuItem onClick={() => createStep('image')}>
                    {isLoading && loadingStepType === 'image' ? (
                        <CircularProgress size={24} sx={{ marginRight: 1 }} />
                    ) : (
                        <ImageIcon sx={{ marginRight: 1 }} />
                    )}
                    {translate(language, "add_image")}
                </MenuItem>
                <MenuItem onClick={() => createStep('invoice')}>
                    {isLoading && loadingStepType === 'invoice' ? (
                        <CircularProgress size={24} sx={{ marginRight: 1 }} />
                    ) : (
                        <StarIcon sx={{ marginRight: 1 }} />
                    )}
                    {translate(language, "add_invoice")}
                </MenuItem>
            </Menu>
        </Box>
    );
}

export default NewStepItem;