import React, { forwardRef } from 'react';
import { styled } from '@mui/system';

// Создаем компонент StyledTextarea с использованием MUI styled и themeParams
const StyledTextareaContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const StyledLabel = styled('label')(({ themeParams }) => ({
    fontSize: '12px',
    color: themeParams.section_header_text_color, // Используем цвет из themeParams
    marginBottom: '5px',
    textTransform: 'uppercase', // Делаем текст заголовка большими буквами
}));

const StyledTextarea = styled('textarea')(({ themeParams }) => ({
    width: '100%',
    padding: '10px 15px',
    fontSize: '16px',
    color: themeParams.text_color,
    borderRadius: '12px',
    border: `1px solid ${themeParams.section_header_text_color}`,
    outline: 'none',
    marginBottom: '10px',
    backgroundColor: themeParams.secondary_bg_color,
    transition: 'border-color 0.3s',
    resize: 'none', // Отключаем возможность изменения размера
    '&:focus': {
        border: `2px solid ${themeParams.button_color}`,
        backgroundColor: themeParams.secondary_bg_color,
    },
}));

// Компонент CustomTextarea с параметрами label, value, onChange и themeParams
const CustomTextarea = forwardRef(({ label, value, onChange, onFocus, onBlur, themeParams, rows = 5 }, ref) => {
    return (
        <StyledTextareaContainer>
            <StyledLabel themeParams={themeParams}>{label}</StyledLabel>
            <StyledTextarea
                ref={ref}  // Используем ref, переданный через forwardRef
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                themeParams={themeParams} // Передаем themeParams для использования цвета
                rows={rows} // Можно настроить количество строк по умолчанию
            />
        </StyledTextareaContainer>
    );
});

export default CustomTextarea;