import React from 'react';
import { Typography } from "@mui/material";
import 'react-markdown-editor-lite/lib/index.css';
import DOMPurify from 'dompurify';
import {styled} from "@mui/system";
import CoverGenerator from "../CoverGenerator";

const DescriptionTypography = styled(Typography)(({ themeParams }) => ({
    marginTop: '8px',
    color: themeParams?.text_color || '#6c757d', // Значение по умолчанию
    fontSize: '0.875rem',
    textAlign: 'left',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
}));

const ImageWrapper = styled('div')({
    width: '100%',
    height: '200px',
    overflow: 'hidden',
});

const StyledImage = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const ContentWrapper = styled('div')({
    padding: '16px',
});

function Invoice({ step, themeParams }) {
    const image_url = step.payload?.image_url;
    const name = step.payload?.name;

    const createMarkup = (text) => {
        let sanitizedText = text
            .replace(/\n/g, '<br/>') // Замена \n на <br/>
            .replace(/<tg-spoiler>/g, '<span style="background-color: #dadada;">') // Замена tg-spoiler на div с серым фоном
            .replace(/<\/tg-spoiler>/g, '</span>') // Замена закрывающего tg-spoiler на закрывающий div
            .replace(/<pre><code class="language-(.*?)">/g, '<div style="background-color: #f5f5f5; padding: 15px; border-radius: 8px; overflow-x: auto;">') // Стилизация блока кода
            .replace(/<\/code><\/pre>/g, '</div>'); // Закрытие стилизованного div

        sanitizedText = DOMPurify.sanitize(sanitizedText);

        return { __html: sanitizedText };
    }

    return (
        <>
            {/* Adding the course image at the top */}
            {<ImageWrapper>
                {image_url ? (
                    <StyledImage src={image_url} alt={name} />
                ) : step.payload?.cover_preset && (
                    (
                        <CoverGenerator width={300} preset={step.payload?.cover_preset}
                                        text={name} />
                    )
                )}
            </ImageWrapper>}

            <ContentWrapper>
                {/* Render the text as Markdown */}
                <DescriptionTypography themeParams={themeParams}>
                    <Typography component="div" dangerouslySetInnerHTML={createMarkup(step.text)} />
                </DescriptionTypography>
            </ContentWrapper>
        </>
    );
}

export default Invoice;