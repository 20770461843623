const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength) + '...';
};

const isIOS = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
};

const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
};

const isWebView = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return (
        (userAgent.includes('iphone') || userAgent.includes('ipad')) &&
        (userAgent.includes('safari') === false || userAgent.includes('wv'))
    );
};

const detectDevice = () => {
    if (isIOS() && isWebView()) {
        return 'iOS WebView';
    } else if (isIOS()) {
        return 'iOS';
    } else if (isAndroid()) {
        return 'Android';
    } else if (isMobile()) {
        return 'Mobile';
    } else {
        return 'Desktop';
    }
};

export { truncateText, isIOS, isAndroid, isMobile, isWebView, detectDevice };