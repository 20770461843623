import React, { useState } from 'react';
import { Card, IconButton, Menu, MenuItem, Divider, CircularProgress, ListItemIcon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { styled } from '@mui/system';
import Text from "./step-view/Text";
import Image from "./step-view/Image";
import Invoice from "./step-view/Invoice";
import { translate } from "../translations";
import {useSelector} from "react-redux";

const StyledCard = styled(Card)(({ themeParams }) => ({
    backgroundColor: themeParams?.section_bg_color || '#fff',
    borderRadius: '20px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    position: 'relative',
}));

const StyledMenuWrapper = styled('div')({
    position: 'absolute',
    top: '10px',
    right: '10px',
    display: 'flex', // Icons in a row
    justifyContent: 'flex-end', // Align to the right
    gap: '5px', // Space between icons
});

// Main StepCard component
const StepCard = ({ handleCardClick, index, course, step, themeParams, language, onMoveUp, onMoveDown, onDelete, onEdit }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [loading, setLoading] = useState(false);
    const selectedStep = useSelector(state => state.selectedStep);

    const handleClick = (event) => {
        event.stopPropagation(); // Prevents event propagation
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation(); // Prevents event propagation
        setAnchorEl(null);
    };

    const handleEditContent = (event) => {
        event.stopPropagation(); // Prevents event propagation
        onEdit(selectedStep?.entity);
        handleClose(event);
    };

    const handleDelete = (event, step) => {
        event.stopPropagation(); // Prevents event propagation
        setLoading(true);
        // Implement delete logic here
        onDelete(selectedStep?.entity);

        setLoading(false);
        handleClose(event);
    };

    const handleMoveUp = (event, index) => {
        event.stopPropagation(); // Prevents event propagation
        onMoveUp(index); // Call function to move step up
    };

    const handleMoveDown = (event, index) => {
        event.stopPropagation(); // Prevents event propagation
        onMoveDown(index); // Call function to move step down
    };

    let Component;
    switch (step.type) {
        case "text":
            Component = Text;
            break;
        case "image":
            Component = Image;
            break;
        case "invoice":
            Component = Invoice;
            break;
        default:
            return (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    Unknown step type: {step.type}
                </div>
            );
    }

    return (
        <StyledCard onClick={handleCardClick} themeParams={themeParams}>
            <Component step={step} themeParams={themeParams} />

            {/* Icons for moving step up, down, and the more menu */}
            <StyledMenuWrapper>
                {course.steps.length > 1 && index !== 0 && step.id === selectedStep.id &&
                <IconButton
                    sx={{
                        backgroundColor: `${themeParams.button_color}AA`,
                        backdropFilter: 'blur(5px)',
                        '&:hover': {
                            backgroundColor: `${themeParams.button_color}CC`,
                        },
                    }}
                    onClick={(e) => handleMoveUp(e, index)}
                    aria-label="move up"
                >
                    <ArrowUpwardIcon sx={{ color: themeParams.button_text_color }} />
                </IconButton>}

                {course.steps.length > 1 && index !== course.steps.length - 1 && step.id === selectedStep.id &&
                <IconButton
                    sx={{
                        backgroundColor: `${themeParams.button_color}AA`,
                        backdropFilter: 'blur(5px)',
                        '&:hover': {
                            backgroundColor: `${themeParams.button_color}CC`,
                        },
                    }}
                    onClick={(e) => handleMoveDown(e, index)}
                    aria-label="move down"
                >
                    <ArrowDownwardIcon sx={{ color: themeParams.button_text_color }} />
                </IconButton>}

                <IconButton
                    sx={{
                        backgroundColor: `${themeParams.button_color}AA`,
                        backdropFilter: 'blur(5px)',
                        '&:hover': {
                            backgroundColor: `${themeParams.button_color}CC`,
                        },
                    }}
                    onClick={handleClick}
                    aria-label="more"
                >
                    <MoreVertIcon sx={{ color: themeParams.button_text_color }} />
                </IconButton>
            </StyledMenuWrapper>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    sx: {
                        backgroundColor: themeParams.section_bg_color,
                        borderRadius: '20px',
                    },
                }}
            >
                <MenuItem onClick={handleEditContent}>
                    <ListItemIcon>
                        {step.is_collection ? <EditIcon /> : <CreateIcon />}
                    </ListItemIcon>
                    {translate(language, 'edit')}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleDelete}>
                    <ListItemIcon>
                        {loading ? <CircularProgress size={16} /> : <DeleteIcon />}
                    </ListItemIcon>
                    {translate(language, 'delete')}
                </MenuItem>
            </Menu>
        </StyledCard>
    );
};

export default StepCard;