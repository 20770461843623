import React from 'react';
import { Typography } from "@mui/material";
import DOMPurify from 'dompurify';
import {styled} from "@mui/system";

const ContentWrapper = styled('div')({
    padding: '16px',
});

const DescriptionTypography = styled(Typography)(({ themeParams, text }) => ({
    marginTop: text.length < 25 ? '50px' : '8px',
    color: themeParams?.text_color || '#6c757d', // Значение по умолчанию
    fontSize: '0.875rem',
    textAlign: 'left',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
}));

function Text({ step, themeParams }) {
    const createMarkup = (text) => {
        let sanitizedText = text
            .replace(/\n/g, '<br/>') // Замена \n на <br/>
            .replace(/<tg-spoiler>/g, '<span style="background-color: #dadada;">') // Замена tg-spoiler на div с серым фоном
            .replace(/<\/tg-spoiler>/g, '</span>') // Замена закрывающего tg-spoiler на закрывающий div
            .replace(/<pre><code class="language-(.*?)">/g, '<div style="background-color: #f5f5f5; padding: 15px; border-radius: 8px; overflow-x: auto;">') // Стилизация блока кода
            .replace(/<\/code><\/pre>/g, '</div>'); // Закрытие стилизованного div

        sanitizedText = DOMPurify.sanitize(sanitizedText);

        return { __html: sanitizedText };
    }

    return (
        <ContentWrapper>
            {/* Render the text as Markdown */}
            <DescriptionTypography themeParams={themeParams} text={step.text}>
                <Typography component="div" dangerouslySetInnerHTML={createMarkup(step.text)} />
            </DescriptionTypography>
        </ContentWrapper>
    );
}

export default Text;
