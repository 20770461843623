import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IframeComm from 'react-iframe-comm'; // Assuming this is a custom package
import { BXStyles } from './PluginStyle';
import { useDispatch, useSelector } from "react-redux";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Divider,
    CircularProgress,
    Drawer, Slide, Box
} from "@mui/material";
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import axios from "axios";
import { styled } from '@mui/system';
import {setSelectedComponent, setEditablePlugin, resortComponents, addComponent, setAddedDirection} from "../../store";
import useWebApp from '../../twa/useWebApp';
import {processImageWithExif} from "../exifHandler";
import {translate} from "../../translations";

const SpinnerContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
});

const Upsert = ({ isSyllabus, viewerWidthPercent = 60, themeParams, language, mode = 'light', }) => {
    const [editLoading, setEditLoading] = useState(true);
    const [modalData] = useState({});
    const [showAbout, setShowAbout] = useState(false);
    const [pluginData, setPluginData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editIframeHeight, setEditIframeHeight] = useState(500);
    const components = useSelector(state => state.components);
    const editIframeRef = useRef(null);
    const pluginName = 'plug_added';
    const dispatch = useDispatch();

    const [settingData, setSettingData] = useState(null);
    const [showSetting, setShowSetting] = useState(false);
    const [eventSource, setEventSource] = useState(false);
    const [settingFormData, setSettingFormData] = useState({});
    const addedDirection = useSelector(state => state.addedDirection);
    const scrollContainerRef = useRef(null);
    const accessToken = useSelector(state => state.accessToken);
    const [kbGap, setKbGap] = useState(0);
    const selectedStep = useSelector(state => state.selectedStep);
    const selectedComponent = useSelector(state => state.selectedComponent);

    // Флаг для предотвращения дублирующихся вызовов
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        setIsEdit(!!addedDirection);
        if (addedDirection) {
            setEditLoading(true);
        }

    }, [addedDirection]);

    let WebApp = useWebApp();

    useEffect(() => {
        const handleButtonClick = () => {
            if (eventSource && eventSource.source) {
                // Добавим проверку для предотвращения повторного вызова
                if (isAdding) {
                    return; // Если добавление уже идет, не выполняем код
                }
                WebApp.MainButton.showProgress(false);
                eventSource.source.postMessage({
                    type: 'TRIGGER_SAVE',
                    componentId: addedDirection.id,
                }, '*');
            }
        };

        WebApp.MainButton.onClick(handleButtonClick);

        return () => {
            WebApp.MainButton.offClick(handleButtonClick); // Удаляем обработчик
        };
    }, [addedDirection, eventSource, isAdding]);

    const getMiniDialogStyles = () => ({
        minWidth: '957px',
        borderRadius: '20px',
        overflowY: 'auto',
    });

    const getModalDialogStyles = () => ({
        minWidth: '957px',
        borderRadius: '20px',
        overflowY: 'auto',
    });

    const AddNewComponent = (state, sortIndex, sorted_map, resp) => {
        axios.post('v1/components', {
            step_id: selectedStep.id,
            plugin_id: addedDirection.id,
            sort_index: sortIndex,
            input_data: JSON.stringify(state),
            sorted_map: sorted_map,
        },{
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }).then(response => {
            const component = response.data.data;
            dispatch(addComponent(component));
            dispatch(setSelectedComponent(component));

            // Сбрасываем флаг после завершения добавления
            setIsAdding(false);

            setIsEdit(false);
            dispatch(setEditablePlugin(null));
            dispatch(setAddedDirection(null));
            WebApp.MainButton.hideProgress();
            WebApp.MainButton.hide();
            setKbGap(0);

            document.activeElement.blur();
            //window.scrollTo(0, 0);
        }).catch(error => {
            console.error('Error creating component:', error);

            setIsEdit(false);
            dispatch(setEditablePlugin(null));
            dispatch(setAddedDirection(null));
            WebApp.MainButton.hideProgress();
            WebApp.MainButton.hide();
            setKbGap(0);

            // Сбрасываем флаг при ошибке
            setIsAdding(false);

            document.activeElement.blur();
        });
    }

    const handleModalClose = () => {
        setShowModal(false);
        resetComponent();
    };

    const resetComponent = () => {
        WebApp.MainButton.hide();
        WebApp.MainButton.hideProgress();
        document.activeElement.blur();

        setIsEdit(false);
        dispatch(setEditablePlugin(null));
        dispatch(setAddedDirection(null));
        eventSource(null);
    };

    const updateState = (resp) => {
        const r = JSON.parse(resp);
        if (r.id === addedDirection.id  && !isAdding) {
            // Устанавливаем флаг, что компонент добавляется
            setIsAdding(true);

            let sortIndex = 1;
            let sorted_map = [];
            let isLastIndex = addedDirection ? addedDirection.isLastPlugin : false;

            if (selectedComponent) {
                let info = resort();
                sortIndex = info.newIndex;
                sorted_map = info.resortedComponents;
            }

            if (!selectedComponent || isLastIndex) {
                let index = 1;
                if (components.length > 0) {
                    const lastComponentSortIndex = components[components.length - 1].sortIndex;
                    sortIndex = (lastComponentSortIndex != null ? lastComponentSortIndex : index) + 1;
                }
            }

            dispatch(resortComponents(sorted_map));
            AddNewComponent(r.state, sortIndex, sorted_map, resp);
        }
    }

    const fetchFile = (event) => {
        const eventData = JSON.parse(event.data);

        // Проверяем, что полученный ID соответствует ID компонента
        if (eventData.id === addedDirection.id) {
            const fileId = eventData.data; // ID файла получаем из данных события

            // Формируем URL для GET запроса
            const url = `v1/storage/file/${fileId}`;

            // Отправляем GET запрос для получения информации о файле
            axios.get(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
                .then(response => {
                    const file = response.data;

                    if (event.source) {
                        event.source.postMessage({
                            type: 'fetchFileComplete',
                            id: addedDirection.id,
                            file: file
                        }, "*");
                    }
                })
                .catch(error => {
                    console.error('Fetch file error:', error);
                });
        }
    };

    const uploadFile = (event) => {
        const r = JSON.parse(event.data);
        if (r.id === addedDirection.id) {
            const fileDataUrl = r.data;
            const originalFileName = r.originalFileName;

            fetch(fileDataUrl)
                .then(res => res.blob())
                .then(blob => {
                    const formData = new FormData();
                    formData.append('file', blob);
                    formData.append('original_file_name', originalFileName);

                    // Предполагаем, что axios уже настроен с baseURL и заголовками авторизации
                    axios.post('v1/storage/upload/file', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${accessToken}`
                        },
                        onUploadProgress: function(progressEvent) {
                            const progress = (progressEvent.loaded / progressEvent.total) * 100;
                            event.source.postMessage({
                                type: 'uploadFileProgress',
                                id: addedDirection.id,
                                progress: progress
                            }, "*");
                        }
                    })
                        .then(response => {
                            const file = response.data.data;
                            let fileURL = "https://t.coob.app/coob/" + file.path;

                            if (event.source) {
                                event.source.postMessage({
                                    type: 'uploadFileComplete',
                                    id: addedDirection.id,
                                    fileURL: fileURL,
                                    fileSize: file.meta.size,
                                    fileID: file.id
                                }, "*");
                            }
                        })
                        .catch(error => {
                            console.error('Upload error:', error);
                            if (event.source) {
                                event.source.postMessage({
                                    type: 'uploadFileError',
                                    id: addedDirection.id,
                                    message: 'Upload failed: ' + error.message
                                }, "*");
                            }
                        });
                })
                .catch(error => {
                    console.error('Error converting Data URL to Blob:', error);
                    if (event.source) {
                        event.source.postMessage({
                            type: 'uploadFileError',
                            id: addedDirection.id,
                            message: 'Error converting Data URL to Blob: ' + error.message
                        }, "*");
                    }
                });
        }
    };

    const uploadVideo = (event) => {
        const r = JSON.parse(event.data);
        if (r.id === addedDirection.id) {
            const videoDataUrl = r.data;

            fetch(videoDataUrl)
                .then(res => res.blob())
                .then(blob => {
                    const formData = new FormData();
                    formData.append('file', blob);

                    // Предполагаем, что axios уже настроен с baseURL и заголовками авторизации
                    axios.post('v1/storage/upload/video', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${accessToken}`
                        },
                        onUploadProgress: function(progressEvent) {
                            const progress = (progressEvent.loaded / progressEvent.total) * 100;
                            event.source.postMessage({
                                type: 'uploadVideoProgress',
                                id: addedDirection.id,
                                progress: progress
                            }, "*");
                        }
                    })
                        .then(response => {
                            const file = response.data.data;
                            let fileId = file.path;
                            let thumbnail = "https://t.coob.app/coob/img_500w/" + file.meta.thumbnail;

                            let videoURL = "https://t.coob.app/coob/video/" + fileId;
                            if (fileId.includes("videos") || fileId.includes("hls")) {
                                videoURL = "https://t.coob.app/coob/" + fileId;
                            }

                            if (event.source) {
                                event.source.postMessage({
                                    type: 'uploadVideoComplete',
                                    id: addedDirection.id,
                                    videoURL: videoURL,
                                    thumbnail: thumbnail,
                                    videoID: file.id
                                }, "*");
                            }
                        })
                        .catch(error => {
                            console.error('Upload error:', error);
                            if (event.source) {
                                event.source.postMessage({
                                    type: 'uploadVideoError',
                                    id: addedDirection.id,
                                    message: 'Upload failed: ' + error.message
                                }, "*");
                            }
                        });
                })
                .catch(error => {
                    console.error('Error converting Data URL to Blob:', error);
                    if (event.source) {
                        event.source.postMessage({
                            type: 'uploadVideoError',
                            id: addedDirection.id,
                            message: 'Error converting Data URL to Blob: ' + error.message
                        }, "*");
                    }
                });
        }
    };

    const uploadImage = async (event) => {
        const r = JSON.parse(event.data);
        if (r.id === addedDirection.id) {
            const imageDataUrl = r.data;

            try {
                // Преобразуем Data URL в Blob
                const response = await fetch(imageDataUrl);
                const originalBlob = await response.blob();

                // Преобразуем Blob в файл для обработки EXIF-данных
                const file = new File([originalBlob], "image.jpg", { type: originalBlob.type });

                // Обрабатываем изображение с учетом EXIF-данных
                const processedBlob = await processImageWithExif(file);

                // Создаем formData для загрузки обработанного изображения
                const formData = new FormData();
                formData.append('file', processedBlob, file.name); // Передаем обработанный Blob

                // Отправляем запрос на сервер
                const uploadResponse = await axios.post('v1/storage/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${accessToken}`
                    }
                });

                const fileId = uploadResponse.data.data;
                let imgURL = `https://t.coob.app/coob/img_500w/${fileId}`;
                if (fileId.includes("videos") || fileId.includes("hls")) {
                    imgURL = `https://t.coob.app/coob/${fileId}`;
                }

                // Проверяем, существует ли event.source
                if (event.source) {
                    event.source.postMessage({
                        type: 'uploadImageComplete',
                        id: addedDirection.id,
                        imageURL: imgURL
                    }, "*");
                }
            } catch (error) {
                console.error('Ошибка при загрузке изображения:', error);
            }
        }
    };

    const handleSettingsClose = () => {
        setShowSetting(false);
        WebApp.MainButton.show();

        const message = {
            refreshState: {
                data: settingFormData,
            },
        };

        if (editIframeRef.current) {
            editIframeRef.current.sendMessage(message);
        }
    };

    const handleInfoClose = () => {
        setShowAbout(false);
        WebApp.MainButton.show();
    };

    const handleSettingFormChange = ({ formData }) => setSettingFormData(formData);

    const resort = () => {
        const dirId = selectedComponent.id; // id компонента, перед которым добавляется новый элемент

        // Шаг 1: Пересортировка и установка новых sort_index
        const sortedComponents = [...components].sort((a, b) => a.sortIndex - b.sortIndex)
            .map((component, index) => ({ ...component, sortIndex: index + 1 }));

        // Шаг 2: Определение newIndex для нового элемента
        const dirIndex = sortedComponents.findIndex(component => component.id === dirId);
        let newIndex = dirIndex !== -1 ? sortedComponents[dirIndex].sortIndex : sortedComponents.length + 1;

        // Шаг 3: Пересортировка с учетом нового элемента
        const result = sortedComponents.map(component => {
            return {
                id: component.id,
                index: component.sortIndex >= newIndex ? component.sortIndex + 1 : component.sortIndex
            };
        });

        return {
            resortedComponents: result,
            newIndex: newIndex,
        };
    };

    const onReceiveMessageEdit = (event) => {
        let data;
        try {
            data = JSON.parse(event.data);
        } catch (e) {
            return;
        }

        if (data.namespace !== 'edit' || data.id !== addedDirection.id) {
            return;
        }

        switch (data.type) {
            case 'set_height':
                setEditHeight(data);
                break;
            case 'update_state':
                updateState(event.data);
                break;
            case 'show_about':
                showAboutComponent(data);
                break;
            case 'show_settings':
                showSettingModal(data);
                break;
            case 'content_loaded':
                contentEditLoaded(event);
                break;
            case 'reset':
                resetComponent();
                break;
            case 'iframe_scroll':
                handleIframeScroll(data);
                break;
            case 'uploadFile':
                uploadFile(event);
                break;
            case 'fetchFile':
                fetchFile(event);
                break;
            case 'uploadVideo':
                uploadVideo(event);
                break;
            case 'uploadImage':
                uploadImage(event);
                break;
            case 'on_focus':
                setOnFocus(data);
                break;
            case 'off_focus':
                setOffFocus(data);
                break;
            case 'save_button_enabled':
                setSaveButtonEnabled(data);
                break;
            case 'show_message':
                showMessageBox(event.data);
                break;
        }
    };

    const showMessageBox = (event) => {
        const r = JSON.parse(event);
        if (r.id === addedDirection.id) {
            let titleSlug = "success_popup_title";
            if (r.variant === "error") {
                titleSlug = "error_popup_title";
            } else if (r.variant === "warning") {
                titleSlug = "warning_popup_title";
            } else if (r.variant === "info") {
                titleSlug = "info_popup_title";
            }

            if (WebApp) {
                WebApp.showPopup({
                    title: translate(language, titleSlug),
                    message: r.message,
                    buttons: [
                        {type: 'cancel'},
                    ]
                });

                WebApp.MainButton.hideProgress();
            }
        }
    };

    const setSaveButtonEnabled = (data) => {
        if (data.id === addedDirection.id) {
            WebApp.MainButton.hide();
        }
    }

    const setOnFocus = (data) => {
        if (data.id === addedDirection.id) {
            //setKbGap(0); // 300
        }
    }

    const setOffFocus = (data) => {
        if (data.id === addedDirection.id) {
            //setKbGap(0);
        }
    }

    const handleIframeScroll = (data) => {
        if (data.id === addedDirection.id) {
            const scrollTop = data.scrollTop;
            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollTo({
                    top: scrollTop,
                    behavior: 'smooth',
                });
            } else {
                window.scrollTo({
                    top: scrollTop,
                    behavior: 'smooth',
                });
            }
        }
    };

    const onReady = () => {};

    const onCloseHandler = () => {
        resetComponent();
    };

    const setEditHeight = (resp) => {
        const height = resp.height;
        if (height > 0) {
            setEditIframeHeight(height);
        }
    };

    const showSettingModal = (resp) => {
        const decodedData = atob(resp.data);
        const parsedData = JSON.parse(decodedData);

        if (!parsedData.UISchema) {
            parsedData.UISchema = {};
        }

        parsedData.UISchema["ui:submitButtonOptions"] = {
            norender: true
        };

        if (!parsedData.JSONSchema) {
            parsedData.JSONSchema = {};
        }

        if (!parsedData.JSONSchema.properties) {
            parsedData.JSONSchema.properties = {};
        }

        setSettingData(parsedData);
        setShowSetting(true);
        WebApp.MainButton.hide();
    };

    const showAboutComponent = (resp) => {
        setPluginData({
            name: resp.name,
            description: resp.description,
            version: resp.version,
        });
        setShowAbout(true);
        WebApp.MainButton.hide();
    };

    const contentEditLoaded = (event) => {
        setEditLoading(false);
        if (event.source) {
            event.source.postMessage({
                type: 'TOGGLE_SAVE_BUTTON',
                componentId: addedDirection.id,
                showSaveButton: false
            }, '*');

            // set telegram button
            WebApp.MainButton.show();
            WebApp.MainButton.setParams({
                text: translate(language, 'save'),
                color: themeParams.button_color,
                text_color: themeParams.button_text_color,
                is_active: true,
            });

            setEventSource(event);
        }
    };

    const postMessageData = {
        bookletixMessage: {
            css: BXStyles(
                viewerWidthPercent,
                themeParams.button_text_color,
                themeParams.button_color,

                themeParams.text_color,
                themeParams.bg_color,
                themeParams.hint_color,
                themeParams.link_color,
                themeParams.button_color,
                themeParams.button_text_color,
                themeParams.secondary_bg_color,
            ),
            data: JSON.stringify({
                isSyllabus: isSyllabus,
                mode: mode,
                language: language,
            }),
        },
    };

    return (
        <Box>
            {addedDirection && (
                <Drawer
                    anchor="bottom"
                    open={isEdit}
                    onClose={onCloseHandler}
                    ref={scrollContainerRef}
                    PaperProps={{
                        style: {
                            borderTopLeftRadius: '16px',
                            borderTopRightRadius: '16px',
                            backgroundColor: themeParams.section_bg_color,
                            filter: 'none',
                            mixBlendMode: 'normal',
                            backgroundImage: 'none',
                            height: (editIframeHeight - 1) + (kbGap) + 'px',
                            overflowY: 'auto',
                            transition: 'height 0.3s ease',
                        },
                    }}
                >
                    <Slide direction="up" in={isEdit} mountOnEnter unmountOnExit>
                        <div> {/* Оборачиваем весь контент в контейнер */}
                            {editLoading && (
                                <SpinnerContainer>
                                    <CircularProgress
                                        sx={{
                                            color: themeParams.button_color,
                                        }}
                                    />
                                </SpinnerContainer>
                            )}
                            <IframeComm
                                key={"edit_frame"}
                                ref={editIframeRef}
                                handleReady={onReady}
                                postMessageData={postMessageData}
                                attributes={{
                                    id: "editIframe_" + pluginName,
                                    name: "edit_" + pluginName,
                                    src: addedDirection.payload.edit,
                                    frameBorder: 0,
                                    width: '100%',
                                    height: editIframeHeight,
                                    allowFullScreen: true,
                                    allow: 'camera; microphone',
                                    style: editLoading ? { opacity: 0 } : {
                                        opacity: 1,
                                        transition: 'opacity 0.4s ease-in-out',
                                    },
                                }}
                                handleReceiveMessage={onReceiveMessageEdit}
                            />
                        </div>
                    </Slide>
                </Drawer>
            )}

            {settingData && (
                <Drawer
                    anchor="bottom"
                    open={showSetting}
                    onClose={handleSettingsClose}
                    PaperProps={{
                        style: {
                            borderTopLeftRadius: '16px',
                            backgroundImage: 'none',
                            borderTopRightRadius: '16px',
                            backgroundColor: themeParams.section_bg_color,
                            overflowY: 'auto',
                        },
                    }}
                >
                    <DialogContent sx={
                        {
                            marginTop: '0px !important',
                        }
                    }>
                        <Form
                            schema={settingData.JSONSchema}
                            uiSchema={settingData.UISchema}
                            onChange={handleSettingFormChange}
                            formData={settingFormData}
                            validator={validator}
                        />
                    </DialogContent>
                    <DialogActions sx={{
                        justifyContent: 'center',
                        padding: '16px',
                        backgroundColor: themeParams.section_bg_color,
                    }}>
                        <Divider
                            sx={{
                                backgroundColor: themeParams.section_separator_color,
                                height: '1px',
                            }}
                        />
                        <Button
                            onClick={handleSettingsClose}
                            sx={{
                                width: '100%',
                                padding: '12px 0',
                                marginBottom: '20px',
                                backgroundColor: themeParams.secondary_bg_color, // Цвет фона кнопки
                                color: themeParams.text_color,
                                borderRadius: '12px',
                                fontSize: '16px',
                                textTransform: 'none',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: themeParams.secondary_bg_color, // Цвет фона при наведении
                                },
                            }}
                        >
                            {translate(language, 'close')}
                        </Button>
                    </DialogActions>
                </Drawer>
            )}

            {pluginData && (
                <Drawer
                    anchor="bottom"
                    open={showAbout}
                    onClose={handleInfoClose}
                    PaperProps={{
                        style: {
                            borderTopLeftRadius: '16px',
                            backgroundImage: 'none',
                            borderTopRightRadius: '16px',
                            backgroundColor: themeParams.section_bg_color,
                            overflowY: 'auto',
                        },
                    }}
                >
                    <DialogContent sx={
                        {
                            marginTop: '0px !important',
                        }
                    }>
                        <Typography variant="h6" sx={{ marginBottom: '16px' }}>
                            {pluginData.name}
                        </Typography>
                        <Typography variant="body1">
                            <div dangerouslySetInnerHTML={{ __html: pluginData.description }} />
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{
                        justifyContent: 'center',
                        padding: '16px',
                        backgroundColor: themeParams.section_bg_color,
                    }}>
                        <Divider
                            sx={{
                                backgroundColor: themeParams.section_separator_color,
                                height: '1px',
                            }}
                        />
                        <Button
                            onClick={handleInfoClose}
                            sx={{
                                width: '100%',
                                padding: '12px 0',
                                marginBottom: '20px',
                                backgroundColor: themeParams.secondary_bg_color,
                                color: themeParams.text_color,
                                borderRadius: '12px',
                                fontSize: '16px',
                                textTransform: 'none',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: themeParams.secondary_bg_color,
                                },
                            }}
                        >
                            {translate(language, 'close')}
                        </Button>
                    </DialogActions>
                </Drawer>
            )}

            {modalData && (
                <Dialog open={showModal} onClose={handleModalClose} PaperProps={{ style: getModalDialogStyles() }}>
                    <DialogTitle>{modalData.title}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            <div dangerouslySetInnerHTML={{ __html: modalData.html }} />
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};

Upsert.propTypes = {
    isSyllabus: PropTypes.bool,
};

export default Upsert;