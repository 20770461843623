const translations = {
    en: {
        completed: "Done!",
        continue: "Keep Going",
        profile: "My Profile",
        assignments: "Tasks",
        payments: "Payments",
        lessons: "Lessons",
        dashboard: "Home",
        progress: "Progress",
        edit_profile: "Update Profile",
        attempts: "Tries",
        comments: "Feedback",
        created: "Created",
        attempt: "Try",
        start: "Start",
        no_notifications: "No notifications to show.",
        no_assignments: "No tasks to show.",
        status: "Status",
        notifications: "Notifications",
        date: "Date",
        no_payments: "No completed payments ⭐",
        current_step_locked: "Complete this step to unlock the next one.",
        step_locked: "Complete the previous steps so that the content is available.",
        reset_progress: "Reset Progress",
        reset_confirm_message: "Are you sure you want to reset your progress? This action cannot be undone.",
        courses: "Recently Created Courses",
        add_course: "Add Course",
        create_course: "Create Course",
        no_courses: "You don't create any courses yet.",
        create_course_header: "Create a Course to Share Your Knowledge",
        delete_course_confirmation: "Delete Course",
        delete_course_warning: "Are you sure you want to delete this course? This action cannot be undone.",
        delete_step_warning: "Are you sure you want to delete this step? This action cannot be undone.",
        delete_component_warning: "Are you sure you want to delete this component? This action cannot be undone.",
        cancel: "Cancel",
        delete: "Delete",
        save: "Save",
        publish_course: "Publish Course",
        available_bots: "Available Telegram Bots",
        description: "Description",
        title: "Title",
        empty: "None",
        choose_preset: "Choose a Preset",
        choose_image: "Choose Image",
        upload_cover_image: "Upload Cover Image",
        upload: "Upload",
        generate: "Generate",
        edit: "Edit",
        draft: "Draft",
        invoice: "Invoice",
        public: "Public",
        image: "Image",
        text: "Text",
        publish_step: "Publish Step",
        amount: "Amount (Stars)",
        edit_step: "Edit step",
        edit_course: "Edit course",
        courses_br: "Courses",
        Text: "Text",
        Image: "Image",
        Video: "Video",
        Document: "Document",
        Code: "Code",
        Audio: "Audio",
        Checkbox: "Checkbox",
        Painter: "Painter",
        Matcher: "Matcher",
        Transcriber: "Transcriber",
        Substituter: "Substituter",
        CodeVisual: "Code Visual",
        Markdown: "Markdown",
        Editor: "Editor",
        Basic: "Basic",
        Simulator: "Simulator",
        System: "System",
        Assignment: "Assignment",
        Other: "Other",
        Codeletix: "Codeletix",
        add_content: "Enter some text and press Enter ...",
        add_content_empty: "Add content to your lesson here ...",
        success_popup_title: "Success!",
        error_popup_title: "Error!",
        warning_popup_title: "Warning!",
        info_popup_title: "Info!",
        close: "Close",
        add_image: "Image",
        add_text: "Text",
        add_invoice: "Invoice",
        content: "Content",
        approved: "Approved",
        rejected: "Rejected",
        pending: "Pending",
        pending_assignments: "Pending Assignments",
        all_assignments: "All Assignments",
        assignment_preview: "Assignment preview",
        assignment_comment: "Comment to the answer",
        actions: "Actions",
        review_assignment_actions: "Review Assignment Actions",
        approve: "Approve",
        decline: "Decline",
        comment_required: "Comment is required",
        assignment: "Assignment",
        reply: "Reply",
        send: "Send",
        courses_menu: "Courses",
        assignments_menu: "Tasks",
        notifications_menu: "Notifications",
        payments_menu: "Payments",
        profile_menu: "Profile",
        bots_menu: "Bots",
        let_feedback: "Leave a comment (optional)"
    },
    ru: {
        let_feedback: "Оставьте комментарий (опционально)",
        bots_menu: "Боты",
        profile_menu: "Профиль",
        payments_menu: "Платежи",
        notifications_menu: "Уведомления",
        assignments_menu: "Задания",
        courses_menu: "Курсы",
        send: "Отправить",
        reply: "Ответить",
        assignment: "Задание",
        comment_required: "Комментарий обязателен",
        decline: "Отклонить",
        approve: "Принять",
        review_assignment_actions: "Ревью задания",
        assignment_comment: "Комментарий к ответу",
        assignment_preview: "Просмотр задания",
        all_assignments: "Все задания",
        pending_assignments: "Задания ожидающие проверки",
        pending: "В ожидании",
        rejected: "Отклонено",
        content: "Контент",
        add_invoice: "Оплата",
        add_text: "Текст",
        add_image: "Изображение",
        close: "Закрыть",
        info_popup_title: "Информация!",
        warning_popup_title: "Предупреждение!",
        error_popup_title: "Ошибка!",
        success_popup_title: "Отлично!",
        add_content_empty: "Добавляйте контент к вашему уроку здесь ...",
        add_content: "Введите текст и нажмите Enter ...",
        Codeletix: "Кодлетикс",
        Other: "Другое",
        Assignment: "Задание",
        System: "Системные",
        Simulator: "Упражнения",
        Basic: "Основные",
        Editor: "Редактор",
        Markdown: "Маркдаун",
        CodeVisual: "Визуализация Кода",
        Substituter: "Замена",
        Transcriber: "Транскрипция",
        Matcher: "Сопоставление",
        Painter: "Рисование",
        Checkbox: "Чекбокс",
        Audio: "Аудио",
        Code: "Код",
        Document: "Документ",
        Video: "Видео",
        Image: "Изображение",
        Text: "Текст",
        courses_br: "Курсы",
        edit_course: "Редактировать курс",
        edit_step: "Редактировать шаг",
        amount: "Сумма (Stars)",
        publish_step: "Опубликовать Шаг",
        text: "Текст",
        image: "Изображение",
        public: "Опубликовано",
        invoice: "Счет",
        draft: "Черновик",
        edit: "Редактировать",
        generate: "Сгенерировать",
        upload: "Загрузить",
        upload_cover_image: "Загрузить обложку",
        choose_image: "Выберите изображение",
        choose_preset: "Выберите шаблон",
        empty: "Пусто",
        title: "Название",
        description: "Описание",
        available_bots: "Доступные Телеграм Боты",
        publish_course: "Опубликовать Курс",
        save: "Сохранить",
        create_course_header: "Создайте курс, чтобы поделиться своими знаниями",
        create_course: "Создать Курс",
        add_course: "Добавить Курс",
        no_courses: "Вы еще не создали ни одного курса.",
        courses: "Последние созданные курсы",
        completed: "Готово!",
        reset_progress: "Сбросить Прогресс",
        reset_confirm_message: "Вы уверены, что хотите сбросить свой прогресс? Это действие нельзя отменить.",
        delete_step_warning: "Вы уверены, что хотите удалить этот шаг? Это действие нельзя отменить.",
        delete_component_warning: "Вы уверены, что хотите удалить этот компонент? Это действие нельзя отменить.",
        continue: "Продолжить",
        start: "Начать",
        profile: "Мой Профиль",
        notifications: "Уведомления",
        no_notifications: "Нет уведомлений для показа.",
        assignments: "Задания",
        lessons: "Уроки",
        payments: "Платежи",
        dashboard: "Главная",
        progress: "Прогресс",
        edit_profile: "Обновить Профиль",
        attempts: "Попытки",
        comments: "Отзывы",
        created: "Создано",
        attempt: "Попробовать",
        no_assignments: "Нет заданий для показа.",
        status: "Статус",
        date: "Дата",
        no_payments: "Нет совершенных платежей ⭐",
        current_step_locked: "Завершите этот шаг, чтобы разблокировать следующий.",
        step_locked: "Завершите предыдущее шаги, чтобы контент был доступен.",
        delete_course_confirmation: "Удалить Курс",
        delete_course_warning: "Вы уверены, что хотите удалить этот курс? Это действие нельзя отменить.",
        cancel: "Отмена",
        delete: "Удалить",
        approved: "Принято",

    }
};

export function translate(languageCode, key) {
    if (!translations[languageCode]?.[key]) {
        return key;
    }

    return translations[languageCode]?.[key] || translations.en[key];
}