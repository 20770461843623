export const AssignmentPluginStyle = (
    viewerWidthPercent,
    buttonColor = '#4c56b2',
    buttonBackgroundColor = '#5e6bdf',
    textColor = '#2f2e2f',
    backgroundColor = '#fff',
    hintColor = '#919091',
    linkColor = '#4c56b2',
    bxButtonColor = '#3A205B',
    bxButtonTextColor = '#fff',
    secondaryBackgroundColor = '#f8f8f8',
    isPending = false
    ) => `
    
 @import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
    
:root {
  --bx-text-color: ${textColor};
  --bx-background-color: ${backgroundColor};
  --bx-hint-color: ${hintColor};
  --bx-link-color: ${linkColor};
  --bx-button-color: ${bxButtonColor};
  --bx-button-text-color: ${bxButtonTextColor};
  --bx-secondary-background-color: ${secondaryBackgroundColor};
}

@media (max-width: 640px) { /* Порог для мобильной версии, обычно 640px или меньше */
    .bx_viewer {
        width: 100%; /* На мобильных устройствах используйте полную ширину */
        margin: auto;
    }

    .bx_viewer .bx_viewer_block img {
        width: 100%;
        height: auto;
    }

    .bx-btn-blue-full, .bx-btn-blue, .bx-btn-purpl, .bx-btn-white {
        padding: 0 15px; /* Меньше отступ для мобильных устройств */
        font-size: 14px; /* Меньший размер шрифта для мобильных устройств */
    }

    .bx-form-input, .bx-form-textarea {
        padding: 8px 15px; /* Меньше отступ для мобильных устройств */
    }

    .bx-plug-wrapper, .bx-form-bottom {
        padding: 15px; /* Меньше отступ для мобильных устройств */
    }

    /* Дополнительные стили для мобильных устройств */
}

html {
  color: ${textColor};
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  font-family: Montserrat, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    color: ${textColor};
}

a {
    background-color: transparent;
    text-decoration: none;
    color: ${textColor};
}

body {
  margin: 0;
}

.bx_viewer {
    width: ${viewerWidthPercent}%; /* или конкретная ширина, например, 500px */
    margin: auto; /* для центрирования */
    pointer-events: none;
}

.bx_viewer .bx_viewer_block img {
    width: 100%; /* делает изображение адаптивным в рамках bx_viewer */
    height: auto; /* сохраняет пропорции изображения */
}

b, strong {
  font-weight: bolder
}

code, kbd, pre, samp {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px
}

.lds-ellipsis div {
  position: absolute;
  top: 13px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0)
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 .6s infinite
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 .6s infinite
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 .6s infinite
}

.block {
    display: block
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 .6s infinite
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0)
  }
  100% {
    transform: scale(1)
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1)
  }
  100% {
    transform: scale(0)
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0)
  }
  100% {
    transform: translate(24px, 0)
  }
}

.bx-btn-blue {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
  display: block;
  border: none;
  text-align: center;
  height: 40px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  padding: 0 20px;
  transition: background-color .15s ease 0s;
  color: var(--bx-button-text-color); 
  background-color: var(--bx-button-color);
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button
}

.bx-btn-blue:hover {
  background-color: var(--bx-button-color); /* Использование переменной для цвета фона при наведении */
}

.bx-btn-blue:focus {
  outline: none;
}

.bx-btn-blue-full {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  width: 100%;
  overflow: visible;
  text-transform: none;
  display: block;
  border: none;
  text-align: center;
  height: 40px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  padding: 0 20px;
  transition: background-color .15s ease 0s;
  color: var(--bx-button-text-color); 
  background-color: var(--bx-button-color);
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button
}

.bx-btn-blue-full:hover {
  background-color: var(--bx-button-color); /* Использование переменной для цвета фона при наведении */
}

.bx-btn-blue-full:focus {
  outline: none;
}

.buttonload {
  visibility: hidden;
}

.emptyButton {
  padding: 12px 24px;
 }
 
.fa {
  margin-left: 10px;
  margin-right: 18px;
  
}
section.loaders .loader {
  display: inline-block;
}
.loader {
  border: 16px solid var(--bx-button-text-color);;
  border-top: 16px solid var(--bx-button-text-color);;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 25px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
.colors2 {
  border-bottom: 16px solid blue;
}
.colors3 {
  border-bottom: 16px solid red;
  border-right: 16px solid green;
}
.colors4 {
  border-bottom: 16px solid red;
  border-right: 16px solid green;
  border-left: 16px solid pink;
}


.bx-btn-purpl {
   text-size-adjust: 100%;
   box-sizing: border-box;
   font-family: inherit;
   margin: 0px;
   overflow: visible;
   text-transform: none;
   appearance: button;
   display: block;
   border: none;
   text-align: center;
   height: 40px;
   border-radius: 12px;
   font-weight: 600;
   font-size: 16px;
   line-height: 16px;
   touch-action: manipulation;
   cursor: pointer;
   white-space: nowrap;
   outline: none;
   padding: 0px 20px;
   transition: background-color 0.15s ease 0s;
   color: rgb(244, 237, 252);
   background-color: rgb(147, 81, 230);
   margin-left: auto;
}
    
.bx-btn-purpl:hover {
   background-color: rgb(117, 64, 184);
}

.bx-btn-white {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  overflow: visible;
  text-transform: none;
  display: block;
  text-align: center;
  height: 40px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  padding: 0 20px;
  transition: background-color .15s ease 0s;
  border: 1px solid #e3e3e3;
  color: #2f2e2f;
  background-color: #fff;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid var(--bx-button-text-color);
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.bx-btn-white:hover {
  background-color: #f1f1f1
}

.bx-btn-white:active {
  background-color: #e3e3e3
}

.bx-btn-white:focus {
  outline: none
}

.bx-form-input {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  background-color: #f1f1f1;
  border-radius: 12px;
  height: 40px;
  padding: 8px 20px;
  color: #2f2e2f;
  border: none;
  font-weight: 500;
  transition: box-shadow .15s ease-out 0s;
  width: 100%
}

.bx-form-input:hover {
  box-shadow: 0 0 1px 1px rgb(200 199 200)
}

.bx-form-input:focus {
  box-shadow: 0 0 1px 1px #9351e6;
  background-color: #fff;
  outline: none
}

.bx-form-textarea {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  overflow: auto;
  resize: none;
  background-color: #f1f1f1;
  border-radius: 12px;
  padding: 8px 18px;
  color: #2f2e2f;
  border: none;
  font-weight: 500;
  width: 100%;
  line-height: 24px;
  transition: box-shadow .15s ease-out 0s
}

.bx-form-textarea:hover {
  box-shadow: 0 0 1px 1px rgb(200 199 200)
}

.bx-form-textarea:focus {
  box-shadow: 0 0 1px 1px rgb(147 81 230);
  background-color: #fff;
  outline: none
}

.bx-form-label {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-family: Montserrat, sans-serif;
  box-sizing: border-box;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-left: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #919091
}

.rounded {
    border-radius: 12px
}

.rounded-lg {
    border-radius: 20px
}

.rounded-full {
    border-radius: 9999px
}

.rounded-b-lg {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px
}

.rounded-tl-lg {
    border-top-left-radius: 20px
}

.rounded-tr-lg {
    border-top-right-radius: 20px
}

.footer_submit {
    display: none;
}

.border {
    border-width: 1px
}

.border-b-2 {
    border-bottom-width: 2px
}

.border-r {
    border-right-width: 1px
}

.border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(241, 241, 241, var(--tw-border-opacity))
}

.border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(202, 203, 207, var(--tw-border-opacity))
}

.bx-success-alert {
    text-size-adjust: 100%;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    padding: 20px;
    color: rgb(19, 103, 16);
    background-color: rgb(244, 251, 243);
    border-radius: 12px;
    line-height: 22px;
    display: ${isPending ? 'none' : 'block'};
}
.bx-wrong-alert {
    text-size-adjust: 100%;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    padding: 20px;
    color: rgb(136, 44, 44);
    background-color: rgb(252, 237, 237);
    border-radius: 12px;
    line-height: 22px;
    display: ${isPending ? 'none' : 'block'};
}
.decline-feedback {
    text-size-adjust: 100%;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    color: rgb(136, 44, 44);
    line-height: 22px;
    box-sizing: border-box;
    display: ${isPending ? 'none' : 'block'};
}
.bx-info-alert {
    text-size-adjust: 100%;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    padding: 20px;
    color: rgb(13, 60, 97);
    background-color: rgb(235, 248, 255);
    border-radius: 12px;
    line-height: 22px;
    display: ${isPending ? 'none' : 'block'};
}
.bx-feedback-alert {
    text-size-adjust: 100%;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    padding: 20px;
    color: rgb(190, 141, 69);
    background-color: rgb(255, 249, 196);
    border-radius: 12px;
    line-height: 22px;
    display: ${isPending ? 'none' : 'block'};
}
 a {
    color: #2f2e2f;
    text-decoration: underline;
    font-weight: 500
}

        .bx-plug-wrapper {
            text-size-adjust: 100%;
            color: #2f2e2f;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            box-sizing: border-box;
            border: 1px solid #e3e3e3;
            border-radius: 12px;
            width: 100%;
            padding: 20px 20px 0
        }
        
        .bx-form-title {
            text-size-adjust: 100%;
            color: #2f2e2f;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            box-sizing: border-box;
            display: flex;
            -webkit-box-align: center;
            align-items: center
        }
        
        .bx-form-top {
            cursor: pointer;
            margin-bottom: 21px;
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            align-items: center
        }

        .bx-plug-name {
            text-size-adjust: 100%;
            font-family: Montserrat, sans-serif;
            box-sizing: border-box;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #000;
            margin: 0 0 0 22px
        }
        
        .bx-form-bottom {
            text-size-adjust: 100%;
            color: #2f2e2f;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            box-sizing: border-box;
            padding: 20px;
            margin: 0 -20px;
            background-color: #f8f8f8;
            border-top: 1px solid #e3e3e3;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px
        }
        
        .bx_form_content {
            padding-bottom: 25px;
        }  
        
`;
