import React from 'react';
import { styled } from '@mui/material/styles';

const FactoryEditor = styled('div')(({ theme, showBorder, themeParams }) => ({
    position: 'relative',
    width: '100%',
    borderRadius: 16,
    borderColor: 'transparent',
    borderStyle: 'dashed',
    borderWidth: 2,
}));

const ChildrenContainer = styled('div')({
    width: '100%',
});

const AddWrapperComponent = ({ children, themeParams}) => {

    return (
        <FactoryEditor
            showBorder={true}
            themeParams={themeParams}
        >
            <ChildrenContainer>{children}</ChildrenContainer>
        </FactoryEditor>
    );
};

export default AddWrapperComponent;