import { SvgIcon } from '@mui/material';
import TextIcon from '@mui/icons-material/TextFields'; // Иконка текста
import ImageIcon from '@mui/icons-material/Image'; // Иконка изображения
import StarIcon from '@mui/icons-material/Star'; // Иконка для инвойса
import BookIcon from '@mui/icons-material/MenuBook'; // Иконка для курса
import AddIcon from '@mui/icons-material/Add'; // Иконка для добавления шага

const StepIconComponent = ({ type, themeParams, isCompleted }) => {
    // Define the color based on isCompleted
    const iconColor = isCompleted ? themeParams.button_color : themeParams.section_header_text_color;

    // Function to return the correct icon with color
    const getIcon = (IconComponent) => (
        <IconComponent sx={{ color: iconColor }} />
    );

    switch (type) {
        case 'text':
            return getIcon(TextIcon);
        case 'image':
            return getIcon(ImageIcon);
        case 'invoice':
            return getIcon(StarIcon);
        case 'course':
            return getIcon(BookIcon);
        case 'add':
            return '';
        default:
            return getIcon(TextIcon);
    }
};

export default StepIconComponent;