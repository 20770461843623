import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {addComponent, setSelectedComponent} from "../../store";
import sanitizeHtml from 'sanitize-html';
import { Box } from "@mui/material";

const ContentEditable = ({ className, disabled, focus, placeholder, themeParams, language }) => {
    const ref = useRef(null);
    const [showPlaceholder, setShowPlaceholder] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const dispatch = useDispatch();
    const components = useSelector(state => state.components);
    const accessToken = useSelector(state => state.accessToken);
    const [boxMargin, setBoxMargin] = useState(0);

    // step component
    const { stepId } = useParams();

    const createImageComponent = async (imgURL) => {
        const imagePluginID = '1773677f-9081-46cc-9147-f17c071bbd4d';

        console.log("imgURL", imgURL);
        let input_data = {
            imgURL: imgURL,
        }

        let index = 1;
        if (components.length > 0) {
            const lastComponentSortIndex = components[components.length - 1].sortIndex;
            index = (lastComponentSortIndex != null ? lastComponentSortIndex : index) + 1;
        }

        const response = await axios.post(`/v1/components`, {
            step_id: stepId,
            input_data: JSON.stringify(input_data),
            plugin_id: imagePluginID,
            sort_index: index,
        });

        return response.data.data;
    }

    /**
     * Create text component (plugin id hardcoded)
     *
     * @returns {Promise<void>}
     *
     * @param text
     * @returns {Promise<*>}
     */
    const createTextComponent = async (text) => {
        const textPluginID = '9089ebc3-9d93-4e0c-a7ec-5ac64d64be3e';

        text = sanitizeHtml(text, {
            allowedTags: ['h3', 'u', 'b', 'i', 'p', 'br', 'ul', 'ol', 'li'],
        })
        let input_data = {
            html: text,
        }

        let index = 1;
        if (components && components.length > 0) {
            const lastComponentSortIndex = components[components.length - 1].sortIndex;
            index = (lastComponentSortIndex != null ? lastComponentSortIndex : index) + 1;
        }

        const response = await axios.post(`/v1/components`, {
            step_id: stepId,
            input_data: JSON.stringify(input_data),
            plugin_id: textPluginID,
            sort_index: index,
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            }
        });

        return response.data.data;
    }

    useEffect(() => {
        if (focus && ref.current) {
            ref.current.focus();
        }
    }, [focus]);

    const handleInput = (e) => {
        const isEmpty = !ref.current.textContent.trim() && !ref.current.innerHTML.includes('<br>') && !ref.current.innerHTML.includes('<p>');
        setShowPlaceholder(isEmpty);

        if (isEmpty && ref.current) {
            ref.current.blur();
        }
    };

    const handleBlur = () => {
        setShowPlaceholder(!ref.current.textContent.trim());

        if (ref.current.innerHTML.trim() !== '') {
            setShowLoader(true);
            setBoxMargin(0);

            let html = ref.current.innerHTML;

            // Заменяем блоковые элементы на <br>
            html = html.replace(/<div>|<p>/g, '<br>').replace(/<\/div>|<\/p>/g, '');

            ref.current.innerHTML = '';

            // Создаем текстовый компонент
            createTextComponent(html).then((component) => {
                setShowLoader(false);
                dispatch(addComponent(component));
                dispatch(setSelectedComponent(component));
            });
        }
    };

    const handleFocus = () => {
        setShowPlaceholder(false);
        ref.current.focus();
        dispatch(setSelectedComponent(null));
        setBoxMargin(0);
    };

    function onKeyDownHandler(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault();  // Предотвращаем вставку новой строки
            setShowLoader(true);

            // Получаем HTML из элемента
            let html = ref.current.innerHTML;

            // Заменяем блоковые элементы на <br> для корректного отображения переносов
            html = html.replace(/<div>|<p>/g, '<br>').replace(/<\/div>|<\/p>/g, '');

            // Очищаем содержимое элемента и снимаем фокус
            ref.current.innerHTML = '';
            ref.current.blur();

            // Создаем текстовый компонент с обновленным HTML
            createTextComponent(html).then((component) => {
                setShowLoader(false);
                dispatch(addComponent(component));
                dispatch(setSelectedComponent(component));
            });
        }
    }

    function retrieveImageFromClipboardAsBlob(pasteEvent, callback) {
        if (!pasteEvent.clipboardData) {
            if (typeof callback === 'function') {
                callback(undefined);
            }
            return;
        }

        let items = pasteEvent.clipboardData.items;
        if (!items) {
            if (typeof callback === 'function') {
                callback(undefined);
            }
            return;
        }

        let blob = null;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') === -1) continue;
            blob = items[i].getAsFile();
            if (blob) {
                break;
            }
        }

        if (typeof callback === 'function') {
            callback(blob);
        }
    }

    function handlePasteEvent(e) {
        e.preventDefault();
        setShowLoader(true);

        if (e.clipboardData && e.clipboardData.types && e.clipboardData.getData) {
            let types = e.clipboardData.types;

            if ((types.includes('text/html') || types.includes('text/plain'))) {
                let textContent = '';
                let html = e.clipboardData.getData('text/html');
                if (html.length > 0) {
                    textContent = html;
                }

                let plain = e.clipboardData.getData('text/plain');
                if (textContent.length === 0 && plain.length > 0) {
                    textContent = plain.replace(/\n/g, '<br />');
                }

                if (textContent.indexOf('img') === -1 && textContent.length > 0) {
                    // Replace this with your dispatch logic
                    createTextComponent(textContent).then((component) => {
                        setShowLoader(false);
                        dispatch(addComponent(component));
                        dispatch(setSelectedComponent(component));
                    });
                }
            }
        }

        retrieveImageFromClipboardAsBlob(e, async (imageBlob) => {
            if (imageBlob) {
                let formData = new FormData();
                formData.append('file', imageBlob);
                setShowLoader(true);

                uploadHandler(formData).then((fileId) => {
                    console.log(fileId);
                    return "https://t.coob.app/coob/img_500w/" + fileId;
                }).then((response) => {
                    console.log(response);
                    createImageComponent(response).then((component) => {
                        setShowLoader(false);
                        dispatch(addComponent(component));
                        dispatch(setSelectedComponent(component));
                    });
                })
            }
        });
    }

    const uploadHandler = async (formData) => {
        const response = await axios.post(`/v1/storage/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`,
            }
        });
        return response.data.data;
    }

    return (
        <Box sx={{ position: 'relative', marginBottom: boxMargin }}>
            {(showPlaceholder && !showLoader) && <div onClick={handleFocus} style={{ position: 'absolute', top: '10px', left: '10px', color: 'grey', fontSize: 20 }}>{placeholder}</div>}
            {showLoader && (<Box>loading</Box>)}
                <Box
                    ref={ref}
                    className={className}
                    contentEditable={!disabled}
                    onInput={handleInput}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onPaste={handlePasteEvent}
                    onKeyDown={onKeyDownHandler}
                    sx={{
                        padding: '10px',
                        minHeight: '20px',
                        cursor: disabled ? 'default' : 'text',
                        '&:focus': {
                            outline: 'none',  // Убираем обводку при фокусе
                        }
                    }}
                />
        </Box>
    );
};

export default ContentEditable;
